import { createStore } from 'vuex'

export default createStore({
    state: {
        // state里面定义了一些要使用的变量
        access_token: '',
        system_pk: '',
        account: ''
    },
    getters: {
        getAccount: (state) => {
            return localStorage.getItem('account')
        }
    },
    mutations: {
        // mutations里面主要是针对state里面的变量进行一些操作的函数，
        //  在登录这个场景中，分别对access token和refresh token有设置（set）、清除（clear）和获取（get）3个操作，一共6个
        // 额外还有一个更新access token的函数
        // access token 和 refresh token 我们是存储在cookie中，这个很简单，看代码就能理解了，想要深入了解的需要自行搜索
        setAccessToken(state, val) {
            state.access_token = val
            localStorage.setItem('access_token', val)
        },
        clearAccessToken(state) {
            // console.log('CLEAR!!!!')
            state.access_token = ''
            localStorage.removeItem('access_token')
            state.system_pk = ''
            localStorage.removeItem('system_pk')
            state.account = ''
            localStorage.removeItem('account')
        },
        getAccessToken(state) {
            state.access_token = state.access_token || localStorage.getItem('access_token')
        },
        setSystemPK(state, val) {
            state.system_pk = val
            localStorage.setItem('system_pk', val)
        },
        clearSystemPK(state) {
            // console.log('CLEAR!!!! system_pk')
            state.system_pk = ''
            localStorage.removeItem('system_pk')
        },
        getSystemPK(state) {
            state.system_pk = state.system_pk || localStorage.getItem('system_pk')
        },
        setAccount(state, val) {
            state.account = val
            localStorage.setItem('account', val)
        },
        clearAccount(state) {
            // console.log('CLEAR!!!! account')
            state.account = ''
            localStorage.removeItem('account')
        },
        getAccount(state) {
            state.account = state.account || localStorage.getItem('account')
        }
    }
})
