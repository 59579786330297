<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备分配项目"
        width="500px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-descriptions
              :model="deviceDispatchForm"
              direction="horizontal"
              :column="1"
              border
          >
            <el-descriptions-item label="设备名称" label-class-name="descriptions-item-label">
              {{ deviceDispatchForm.deviceName }}
            </el-descriptions-item>
            <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
              {{ deviceDispatchForm.deviceMac }}
            </el-descriptions-item>
            <el-descriptions-item label="设备所属代理商" label-class-name="descriptions-item-label">
              {{ deviceDispatchForm.agentName }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 20px;">
            <el-form label-width="100px" :model="deviceDispatchForm" ref="deviceDispatchFormRef">
              <el-form-item prop="projectKey" label="项目">
                <el-select
                  v-model="deviceDispatchForm.projectName"
                  placeholder="请选择项目"
                  reserve-keyword
                  clearable
                  filterable
                  remote
                  :remote-method="loadProjectList"
                  :loading="loadingProjectListQuery"
                  @change="refreshProjectData"
                  @focus="loadProjectList"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.projectKey"
                    :label="item.projectName"
                    :value="item.projectKey"
                  />
                </el-select>
              </el-form-item>
              <el-form-item style="margin-top: 20px;">
                <el-button type="primary" @click="doDeviceDispatch">分配</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed, ref } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';

    export default {
        name: 'DialogDeviceDispatchProject',
        props: {
          deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceDispatchFormRef = ref(null);
            const deviceDispatchForm = reactive({
                deviceKey: '',
                deviceName: '',
                deviceMac: '',
                projectKey: '',
                projectName: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              // console.log('DialogDeviceDispatch-openDialog', props.deviceKey, deviceDispatchForm);
              deviceDispatchFormRef.value.resetFields();
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        deviceDispatchForm.deviceKey = device.deviceKey;
                        deviceDispatchForm.deviceName = device.deviceName;
                        deviceDispatchForm.deviceMac = device.deviceMac;
                        deviceDispatchForm.agentKey = device.agentKey;
                        deviceDispatchForm.agentName = device.agentName;
                        deviceDispatchForm.projectKey = device.projectKey;
                        deviceDispatchForm.projectName = device.projectName;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            };

            const projectList = ref([])
            const loadingProjectListQuery = ref(false)

            const refreshProjectData = (val) => {
              deviceDispatchForm.projectKey = val;
            }

            const loadProjectList = (param) => {
              const queryParam = {
                projectName: '',
                agentKey: deviceDispatchForm.agentKey
              };
              if (typeof(param) === 'string') {
                queryParam.projectName = param;
              }
              loadingProjectListQuery.value = true
                setTimeout(() => {
                  request.queryProjects(1, 10000, queryParam).then(res => {
                    if (res.success) {
                        projectList.value = [];
                        res.data.rows.forEach((item) => {
                          projectList.value.push({
                            projectKey: item.projectKey,
                            projectName: item.projectName
                          });
                        });
                    } else {
                      ElMessage.error('获取项目数据失败');
                    }
                  }).catch(error => {
                      console.log(error)
                  });
                  loadingProjectListQuery.value = false
                }, 300)
            }

            const doDeviceDispatch = () => {
              request.updateDeviceProject(props.deviceKey, deviceDispatchForm.projectKey).then(res => {
                    if (res.success) {
                        ElMessage.success(res.message);
                        ctx.emit('update:visible', false);
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
              // console.log('DialogDeviceDispatch-doDeviceDispatch', props.deviceKey, deviceDispatchForm);
            }

            return {
              projectList,
              loadingProjectListQuery,
              deviceDispatchFormRef,
              deviceDispatchForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              refreshProjectData,
              loadProjectList,
              doDeviceDispatch
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
