export default {
    getTimestampStr: (timestamp) => {
        if (timestamp === null || timestamp === undefined) {
            return '-';
        }
        const date = new Date(timestamp);
        const Year = date.getFullYear();
        const Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        const Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        const Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        const Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        const Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Year + '-' + Moth + '-' + Day + ' ' + Hour +':'+ Minute + ':' + Sechond;
    },
    isNull: (obj) => {
        return obj === null || typeof (obj) === 'undefined';
    },
    isNotNull: (obj) => {
        return obj !== null && typeof (obj) !== 'undefined';
    }
}
