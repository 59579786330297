import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import
{
    ElContainer,
    ElAside,
    ElHeader,
    ElMain,
    ElFooter,
    ElIcon,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElConfigProvider,
    ElRow,
    ElCol,
    ElButton,
    ElForm,
    ElFormItem,
    ElInput,
    ElCard,
    ElTableV2,
    ElTable,
    ElTableColumn,
    ElText,
    ElAutoResizer,
    ElDialog,
    ElTabs,
    ElTabPane,
    ElDescriptions,
    ElDescriptionsItem,
    ElPagination,
    ElNotification,
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElTooltip,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElSwitch,
    ElPopconfirm,
    ElStatistic,
    ElTreeV2,
    ElLoading,
    ElTag,
    ElDivider,
    ElResult,
    ElSpace,
    ElRadio,
    ElRadioGroup,
    ID_INJECTION_KEY
} from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import '@/assets/font/iconfont.css';
import '@/assets/font/iconfont.js';
import axios from 'axios'
import store from './store/index.js'

axios.defaults.baseURL = 'http://localhost:39631'
const app = createApp(App)
router.beforeEach((to, from, next) => {
    store.commit('getAccessToken')
    const token = store.state.access_token
    if (!token && to.name !== 'login') {
        ElNotification({
            title: '登录失效',
            message: '请重新登录系统',
            type: 'warning'
          })
        next({ name: 'login' })
    } else if (token && to.name === 'login') {
        next({ name: 'index' })
    } else {
        next()
    }
})
app.provide(ID_INJECTION_KEY, {
  prefix: Math.floor(Math.random() * 10000),
  current: 0
})
app.use(ElRow)
app.use(ElCol)
app.use(ElButton)
app.use(ElForm)
app.use(ElFormItem)
app.use(ElInput)
app.use(ElCard)
app.use(ElTableV2)
app.use(ElAutoResizer)
app.use(ElDialog)
app.use(ElTable)
app.use(ElTableColumn)
app.use(ElTabs)
app.use(ElTabPane)
app.use(ElDescriptions)
app.use(ElDescriptionsItem)
app.use(ElPagination)
app.use(ElConfigProvider)
app.use(ElContainer)
app.use(ElAside)
app.use(ElHeader)
app.use(ElMain)
app.use(ElFooter)
app.use(ElIcon)
app.use(ElDropdown)
app.use(ElDropdownItem)
app.use(ElDropdownMenu)
app.use(ElText)
app.use(ElMenu)
app.use(ElMenuItem)
app.use(ElSubMenu)
app.use(ElBreadcrumb)
app.use(ElBreadcrumbItem)
app.use(ElTooltip)
app.use(ElInputNumber)
app.use(ElSelect)
app.use(ElOption)
app.use(ElSwitch)
app.use(ElPopconfirm)
app.use(ElStatistic)
app.use(ElTreeV2)
app.use(ElTag)
app.use(ElDivider)
app.use(ElResult)
app.use(ElLoading)
app.use(ElSpace)
app.use(ElRadio)
app.use(ElRadioGroup)
app.use(store)
app.use(router)
app.mount('#app')
app.config.globalProperties.$axios = axios

const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}
