import axios from 'axios';
import { ElNotification } from 'element-plus'
import router from '../router';
import store from '../store/index.js';

// 设置默认请求头
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 注意：axios默认返回的就是promise
const service = axios.create({
    //axios 请求中的公共url前缀。例如: /dev-api
    // baseURL: '/api/v1',
    baseURL: '/v1',
    //请求超时时间
    timeout: 15000
  })

//request响应拦截器
service.interceptors.request.use(config => {
    const token = store.state.access_token
    if (token) {
      config.headers.Token = token;
    }
    if ((config.method === 'get' || config.method === 'delete') && config.params) {
      let url = config.url + '?';
      for (const key of Object.keys(config.params)) {
        const value = config.params[key];
        const uriPart = encodeURIComponent(key) + '=';
        if (value !== null && typeof (value) !== 'undefined') {
          if (typeof (value) === 'object') {
            for (const subKey of Object.keys(value)) {
              const params = key + '[' + subKey + ']';
              const subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[subKey]) + '&';
            }
          } else {
            url += uriPart + encodeURIComponent(value) + '&';
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  }, error => {
    console.log(error);
    Promise.reject(error);
  })

  //response响应拦截器
  service.interceptors.response.use((response) => {
    // console.log(response)
    const status = response.status || 200;
    const success = response.data.success;
    const msg = response.data.message || '未知错误';
    if (response.request.responseURL.includes('security')){
        const sessionid = response.headers.token
        if (sessionid) {
            store.commit('setAccessToken', sessionid);
        }
    }
    // 401 认证失败，密码或账号问题
    if (status === 401) {
        ElNotification({
            title: '登录失效',
            message: '请重新登录系统',
            type: 'warning'
          })
        store.commit('clearAccessToken')
        router.push({
          path: '/login'
        });
    } else if (status === 500) {
        ElNotification({
            title: '系统错误',
            message: '系统发生错误，请稍后再试',
            type: 'error'
          })
      //失败也需要返回promise对象哦！
      return Promise.reject(new Error(msg));
    } else if (!success) {
        ElNotification({
            title: '操作失败',
            message: msg,
            type: 'error'
          })
      //失败也需要返回promise对象哦！
      return Promise.reject(new Error(msg));
    } else {
        // code === 200 只将对应数据返回
      return response.data;
    }
  }, error => {
    console.log('err' + error)
    let { message } = error;
    if (message === 'Network Error') {
      message = '连接异常';
    } else if (message.includes('timeout')) {
      message = '请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '请求失败 [' + message.substr(message.length - 3) + ']';
    }
    ElNotification({
        title: '系统错误',
        message: message,
        type: 'error'
      })
    return Promise.reject(error)
  })

export default service
