import request from './request.js'

export default {
    querySystemSecurityCode: () => {
        return request({
            url: '/systems/security',
            method: 'post'
        })
    },
    login: (body) => {
        return request({
            url: '/systems/login',
            method: 'post',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: body
        })
    },
    logout: () => {
        return request({
            url: '/systems/logout',
            method: 'post',
            data: {}
        })
    },
    resetPassword: (body) => {
        return request({
            url: '/systems/reset',
            method: 'patch',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: body
        })
    },
    /* ==========  数据看板接口  ========== */
    queryDashboardTrends: () => {
        return request({
            url: '/dashboards/trends',
            method: 'get'
        })
    },
    queryDashboardOnlineDevices: () => {
        return request({
            url: '/dashboards/devices/online',
            method: 'get'
        })
    },
    queryDashboardCalls: () => {
        return request({
            url: '/dashboards/calls',
            method: 'get'
        })
    },
    queryDashboardDeviceActivations: () => {
        return request({
            url: '/dashboards/activations',
            method: 'get'
        })
    },
    queryDashboardEvents: (page, pageSize, deviceKey) => {
        const requestParam = {
            ppage: page,
            pnumber: pageSize,
            deviceKey: deviceKey
        };
        return request({
            url: '/dashboards/events',
            method: 'get',
            params: requestParam
        })
    },
    /* ==========  账号接口  ========== */
    createAccount: (param) => {
        return request({
            url: '/accounts',
            method: 'post',
            data: param
        })
    },
    updateAccount: (accountKey, param) => {
        return request({
            url: '/accounts/' + accountKey,
            method: 'patch',
            data: param
        })
    },
    resetAccount: (accountKey) => {
        return request({
            url: '/accounts/' + accountKey + '/reset',
            method: 'patch'
        })
    },
    deleteAccount: (accountKey) => {
        return request({
            url: '/accounts/' + accountKey,
            method: 'delete'
        })
    },
    queryAccounts: (page, pageSize, param) => {
        param.ppage = page;
        param.pnumber = pageSize;
        return request({
            url: '/accounts',
            method: 'get',
            params: param
        })
    },
    /* ==========  代理商接口  ========== */
    createAgent: (param) => {
        return request({
            url: '/agents',
            method: 'post',
            data: param
        })
    },
    updateAgent: (agentKey, param) => {
        return request({
            url: '/agents/' + agentKey,
            method: 'patch',
            data: param
        })
    },
    queryAgents: (page, pageSize, param) => {
        param.ppage = page;
        param.pnumber = pageSize;
        return request({
            url: '/agents',
            method: 'get',
            params: param
        })
    },
    queryAgentDetail: (agentKey) => {
        return request({
            url: '/agents/' + agentKey,
            method: 'get',
            params: {}
        })
    },
    queryAgentTree: (param) => {
        return request({
            url: '/agents/trees',
            method: 'get',
            params: param
        })
    },
    queryAgentProjectTree: (param) => {
        return request({
            url: '/agents/projects/trees',
            method: 'get',
            params: param
        })
    },
    deleteAgent: (agentKey) => {
        return request({
            url: '/agents/' + agentKey,
            method: 'delete',
            params: {}
        })
    },
    /* ==========  项目接口  ========== */
    createProject: (param) => {
        return request({
            url: '/projects',
            method: 'post',
            data: param
        })
    },
    updateProject: (projectKey, param) => {
        return request({
            url: '/projects/' + projectKey,
            method: 'patch',
            data: param
        })
    },
    queryProjects: (page, pageSize, param) => {
        param.ppage = page;
        param.pnumber = pageSize;
        return request({
            url: '/projects',
            method: 'get',
            params: param
        })
    },
    queryProjectDetail: (projectKey) => {
        return request({
            url: '/projects/' + projectKey,
            method: 'get'
        })
    },
    /* ==========  通话记录接口  ========== */
    queryCallRecords: (page, pageSize, param) => {
        param.ppage = page;
        param.pnumber = pageSize;
        return request({
            url: '/records/calls',
            method: 'get',
            params: param
        })
    },
    queryCallRecordDetail: (callRecordKey) => {
        return request({
            url: '/records/calls/' + callRecordKey,
            method: 'get'
        })
    },
    removeCallRecords: (callRecordKey) => {
        const param = {
            callRecordKeys: [
                callRecordKey
            ]
        };
        return request({
            url: '/records/calls',
            method: 'patch',
            data: param
        })
    },
    /* ==========  设备接口  ========== */
    createVirtualDevice: (param) => {
        return request({
            url: '/devices/virtual',
            method: 'post',
            data: param
        })
    },
    updateDevice: (deviceKey, param) => {
        return request({
            url: '/devices/' + deviceKey,
            method: 'patch',
            data: param
        })
    },
    updateDeviceMac: (deviceKey, editedDeviceMac) => {
        return request({
            url: '/devices/' + deviceKey + '/macs',
            method: 'patch',
            data: {
                deviceMac: editedDeviceMac
            }
        })
    },
    updateDeviceProject: (deviceKey, projectKey) => {
        return request({
            url: '/devices/' + deviceKey + '/projects',
            method: 'patch',
            data: {
                projectKey: projectKey
            }
        })
    },
    updateDeviceAgent: (deviceKey, agentKey) => {
        return request({
            url: '/devices/' + deviceKey + '/agents',
            method: 'patch',
            data: {
                agentKey: agentKey
            }
        })
    },
    updateDeviceMaster: (deviceKey) => {
        return request({
            url: '/devices/' + deviceKey + '/masters',
            method: 'patch',
            data: {}
        })
    },
    updateDeviceTuningParameter: (deviceKey, param) => {
        return request({
            url: '/devices/' + deviceKey + '/tunnings',
            method: 'patch',
            data: param
        })
    },
    matchDevice: (deviceKey, param) => {
        return request({
            url: '/devices/' + deviceKey + '/match',
            method: 'post',
            data: param
        })
    },
    replaceDevice: (deviceKey, param) => {
        return request({
            url: '/devices/' + deviceKey + '/replace',
            method: 'post',
            data: param
        })
    },
    deleteDevices: (param) => {
        return request({
            url: '/devices',
            method: 'delete',
            params: param
        })
    },
    queryDevices: (page, pageSize, param) => {
        param.ppage = page;
        param.pnumber = pageSize;
        return request({
            url: '/devices',
            method: 'get',
            params: param
        })
    },
    queryDeviceDetail: (deviceKey) => {
        return request({
            url: '/devices/' + deviceKey,
            method: 'get'
        })
    },
    bindDevice: (param) => {
        return request({
            url: '/devices/macs/binds',
            method: 'patch',
            data: param
        })
    },
    batchTransferDevice: (param) => {
        return request({
            url: '/devices/batch/transfer',
            method: 'post',
            data: param
        })
    },
    resetDevicePwd: (deviceKey) => {
        return request({
            url: '/devices/' + deviceKey + '/pwd/reset',
            method: 'post'
        })
    },
    syncDevice: (deviceKey) => {
        return request({
            url: '/devices/' + deviceKey + '/sync',
            method: 'post'
        })
    }
}
