<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备编号绑定激活"
        width="600px"
        :close-on-click-modal="false"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <div>
            <el-descriptions
              :model="deviceBindInfoForm"
              direction="horizontal"
              :column="1"
              border
            >
              <el-descriptions-item label="上个设备绑定结果" label-class-name="descriptions-item-label">
                {{ deviceBindInfoForm.information }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <el-form label-width="150px" style="margin-top: 20px" :model="deviceBindForm" ref="deviceBindFormRef">
            <el-form-item prop="deviceNo" label="设备编号">
              <el-input type="text" v-model="deviceBindForm.deviceNo" placeholder="请扫描模块上二维码或输入设备编号" clearable @input="deviceBindForm.deviceNo = deviceBindForm.deviceNo.replace(/\s+/g,'')"></el-input>
            </el-form-item>
            <el-form-item prop="bindCode" label="设备播放四位绑定码">
              <el-input type="text" v-model="deviceBindForm.bindCode" clearable maxlength="4" @input="deviceBindForm.bindCode = deviceBindForm.bindCode.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item prop="partLimit" label="通话局数限制">
                <el-input-number v-model="deviceBindForm.partLimit" :min="1" :max="4" />
              </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doDeviceBind">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogDeviceBind',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceBindInfoFormRef = ref(null);
            const deviceBindInfoForm = reactive({
                information: ''
            });

            const deviceBindFormRef = ref(null);
            const deviceBindForm = reactive({
              deviceNo: '',
              bindCode: '',
              partLimit: 4
            });

            const closeDialog = () => {
              deviceBindFormRef.value.resetFields();
              ctx.emit('update:visible', false);
              props.reload();
            };

            const openDialog = () => {
              // console.log('DialogDeviceBind-openDialog', deviceBindForm, deviceBindInfoForm);
            };

            const doDeviceBind = () => {
              const bindParam = {
                deviceNo: deviceBindForm.deviceNo,
                bindCode: deviceBindForm.bindCode,
                partLimit: deviceBindForm.partLimit
              };
              request.bindDevice(bindParam).then(res => {
                if (res.success) {
                  ElMessage.success('设备编码绑定成功');
                  deviceBindInfoForm.information = res.data
                  deviceBindFormRef.value.resetFields();
                  props.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogDeviceBind-doDeviceBind', props, deviceBindForm);
            }

            return {
              deviceBindInfoFormRef,
              deviceBindInfoForm,
              deviceBindFormRef,
              deviceBindForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              doDeviceBind
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
