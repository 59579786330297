<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="设备IMEI" prop="deviceImei">
          <el-input v-model="searchForm.deviceImei" placeholder="请扫描模块上二维码或输入设备IMEI" style="width: 260px" @input="onDeviceIMEIInput" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备SIM" prop="simIccid">
          <el-input v-model="searchForm.simIccid" placeholder="请输入设备SIM卡ICCID码" style="width: 260px" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceMac">
          <el-input v-model="searchForm.deviceMac" placeholder="请输入设备编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="在线状态" prop="onlineState">
          <el-select v-model="searchForm.onlineState" class="m-2" placeholder="请选择设备在线状态" clearable>
            <el-option
              v-for="item in [{value: 0, label: '离线'},{value: 1, label: '在线'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" style="width: 260px" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="searchForm.deviceType" class="m-2" placeholder="请选择设备类型" style="width: 260px" clearable>
            <el-option
              v-for="item in [{value: 1, label: '主机'},{value: 0, label: '分机'},{value: 2, label: '主机(虚拟)'},]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目名称" prop="projectName">
          <el-input v-model="searchForm.projectName" placeholder="请输入所属项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="deviceTypeName" label="设备类型" width="100"/>
      <el-table-column prop="deviceMac" label="设备编号" width="180" v-if="currentAccount === 'adsysmin'">
        <template #default="scope">
          <el-button link @click="handleDeviceMacEdit(scope.row.deviceKey)">{{ null === scope.row.deviceMac ? '—' : scope.row.deviceMac }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="deviceMac" label="设备编号" width="180" v-if="currentAccount !== 'adsysmin'"/>
      <el-table-column prop="networkQuality" label="在线状态" align="center" width="80">
        <template #default="scope">
            <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
        </template>
      </el-table-column>
      <el-table-column prop="masterDeviceName" label="所属设备" width="160" show-overflow-tooltip/>
      <el-table-column prop="agentName" label="归属账号" width="180" align="center" show-overflow-tooltip/>
      <el-table-column prop="deviceName" label="设备名称" width="160" show-overflow-tooltip/>
      <el-table-column prop="callNumberCount" label="局数" align="center" width="80"/>
      <el-table-column prop="onlineTime" label="最近上线时间" align="center" width="165"/>
      <el-table-column prop="imei" label="设备IMEI" width="150"/>
      <el-table-column prop="simIccid" label="SIM-ICCID" width="200"/>
      <el-table-column prop="projectName" label="所属项目" width="180" show-overflow-tooltip/>
      <el-table-column fixed="right" label="操作" width="200" align="center">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleDeviceDetail(scope.row.deviceKey)">详情</el-button>
          <el-button link type="primary" size="small" @click="handleDeviceEdit(scope.row.deviceKey)">设置</el-button>
          <el-dropdown trigger="click" :row="scope.row">
            <el-button text type="primary" size="small">
              更多操作<el-icon class="el-icon--right"><ArrowDown /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="syncDevice(scope.row.deviceKey)">同步</el-dropdown-item>
                <div v-show="scope.row.deviceType === 0">
                  <el-dropdown-item @click="handleDeviceMatch(scope.row.deviceKey)">对码</el-dropdown-item>
                </div>
                <div v-show="scope.row.deviceType === 1 || scope.row.deviceType === 2">
                    <el-dropdown-item @click="showConfirmMasterDeviceDialog(scope.row.deviceKey)">
                        设为主设备
                    </el-dropdown-item>
                </div>
                <el-dropdown-item @click="handleDeviceDispatchAgent(scope.row.deviceKey)">{{ scope.row.agentKey === null ? '设置代理商' : '变更代理商' }}</el-dropdown-item>
                <div v-show="scope.row.agentKey !== null">
                  <el-dropdown-item @click="handleDeviceDispatchProject(scope.row.deviceKey)">分配项目</el-dropdown-item>
                </div>
                <el-dropdown-item @click="handleDeviceChange(scope.row)">更换设备</el-dropdown-item>
                <el-dropdown-item @click="handleTuningParameters(scope.row.deviceKey)">音频参数设置</el-dropdown-item>
                <div v-if="currentAccount === 'adsysmin' && (scope.row.deviceType === 1 || scope.row.deviceType === 2)">
                    <el-dropdown-item @click="resetDevicePwd(scope.row.deviceKey)">
                        重置密码
                    </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[15, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      v-model="deviceMasterProps.visible"
      title="设置确认"
      width="30%"
      destroy-on-close
    >
      <span>确定要将此设备设置为项目主设备?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deviceMasterProps.visible = false">取消</el-button>
          <el-button type="primary" @click="handleDeviceMaster()">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>

    <DialogDeviceDetail
      v-model:visible="deviceDetailProps.visible"
      v-model:deviceKey="deviceDetailProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceEdit
      v-model:visible="deviceEditProps.visible"
      v-model:deviceKey="deviceEditProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceMatch
      v-model:visible="deviceMatchProps.visible"
      v-model:deviceKey="deviceMatchProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceDispatchAgent
      v-model:visible="deviceDispatchAgentProps.visible"
      v-model:deviceKey="deviceDispatchAgentProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceDispatchProject
      v-model:visible="deviceDispatchProjectProps.visible"
      v-model:deviceKey="deviceDispatchProjectProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceMacEdit
      v-model:visible="deviceMacEditProps.visible"
      v-model:deviceKey="deviceMacEditProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceChange
      v-model:visible="deviceChangeProps.visible"
      v-model:device="deviceChangeProps.device"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceTuningParameterUpdate
      v-model:visible="deviceTuningParameterChangeProps.visible"
      v-model:deviceKey="deviceTuningParameterChangeProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import { ArrowDown } from '@element-plus/icons-vue'
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import IconNetwork from '@/components/icon/IconNetwork.vue';
  import DialogDeviceDetail from '@/components/dialog/DialogDeviceDetail.vue';
  import DialogDeviceEdit from '@/components/dialog/DialogDeviceEdit.vue';
  import DialogDeviceMatch from '@/components/dialog/DialogDeviceMatch.vue';
  import DialogDeviceDispatchAgent from '@/components/dialog/DialogDeviceDispatchAgent.vue';
  import DialogDeviceDispatchProject from '@/components/dialog/DialogDeviceDispatchProject.vue';
  import DialogDeviceMacEdit from '@/components/dialog/DialogDeviceMacEdit.vue';
  import DialogDeviceChange from '@/components/dialog/DialogDeviceChange.vue';
  import DialogDeviceTuningParameterUpdate from '@/components/dialog/DialogDeviceTuningParameterUpdate.vue';

  const currentAccount = ref(localStorage.getItem('account'))

  const tableData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(15);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryDeviceList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryDeviceList();
  }

  const queryDeviceList = () => {
    const queryParam = { isRemoved: false };
    if (anziotUtils.isNotNull(searchForm.deviceMac)) {
      queryParam.deviceMac = searchForm.deviceMac;
    }
    if (anziotUtils.isNotNull(searchForm.deviceImei)) {
      queryParam.deviceImei = searchForm.deviceImei;
    }
    if (anziotUtils.isNotNull(searchForm.simIccid)) {
      queryParam.simIccid = searchForm.simIccid;
    }
    if (anziotUtils.isNotNull(searchForm.onlineState)) {
      queryParam.onlineState = searchForm.onlineState;
    }
    if (anziotUtils.isNotNull(searchForm.projectName)) {
      queryParam.projectName = searchForm.projectName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceName)) {
      queryParam.deviceName = searchForm.deviceName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceType)) {
      queryParam.deviceType = searchForm.deviceType;
    }
    request.queryDevices(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            tableData.value.push({
              deviceKey: item.deviceKey,
              masterDeviceName: item.masterDeviceName,
              projectName: item.projectName,
              agentName: item.agentName,
              deviceName: item.deviceName,
              deviceMac: item.deviceMac,
              imei: item.deviceImei,
              simIccid: item.simIccid,
              deviceType: item.deviceType,
              deviceTypeName: item.deviceTypeName,
              onlineStatus: item.onlineStatus,
              networkQuality: item.networkQuality,
              callNumberCount: item.callNumberCount,
              onlineTime: anziotUtils.getTimestampStr(item.onlineTime),
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取项目数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  };

  const searchFormRef = ref(null);
  const searchForm = reactive({
    deviceName: '',
    projectName: '',
    deviceType: '',
    deviceImei: '',
    simIccid: '',
    onlineState: ''
  });
  const onReset = () => {
    searchFormRef.value.resetFields();
    queryDeviceList();
  }
  const onSearch = () => {
    queryDeviceList();
  };

  const onDeviceIMEIInput = () => {
    if (searchForm.deviceImei.length === 0) {
      return
    }
    const charCount = searchForm.deviceImei.split(';').length - 1
    if (charCount !== 6) {
      return
    }
    queryDeviceList();
  }

  onActivated(
    () => {
      queryDeviceList();
    }
  )

  /* 对话框 */
  const deviceDetailProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceEditProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceMatchProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceMacEditProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceDispatchAgentProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceDispatchProjectProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceMasterProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceChangeProps = ref({ visible: false, device: undefined, reload: () => {} })
  const deviceTuningParameterChangeProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })

  const handleDeviceDetail = (deviceKey) => {
    deviceDetailProps.value.visible = true
    deviceDetailProps.value.deviceKey = deviceKey
    deviceDetailProps.value.reload = queryDeviceList
    // console.log('handleDeviceDetail', deviceKey, deviceDetailProps.value)
  }

  const handleDeviceMatch = (deviceKey) => {
    deviceMatchProps.value.visible = true
    deviceMatchProps.value.deviceKey = deviceKey
    deviceMatchProps.value.reload = queryDeviceList
    // console.log('handleDeviceMatch', deviceKey, deviceMatchProps.value)
  }

  const handleDeviceMacEdit = (deviceKey) => {
    deviceMacEditProps.value.visible = true
    deviceMacEditProps.value.deviceKey = deviceKey
    deviceMacEditProps.value.reload = queryDeviceList
    // console.log('handleDeviceMacEdit', deviceKey, deviceMacEditProps.value)
  }

  const handleDeviceEdit = (deviceKey) => {
    deviceEditProps.value.visible = true
    deviceEditProps.value.deviceKey = deviceKey
    deviceEditProps.value.reload = queryDeviceList
    // console.log('handleDeviceEdit', deviceKey, deviceEditProps.value)
  }

  const handleDeviceDispatchAgent = (deviceKey) => {
    deviceDispatchAgentProps.value.visible = true
    deviceDispatchAgentProps.value.deviceKey = deviceKey
    deviceDispatchAgentProps.value.reload = queryDeviceList
    // console.log('handleDeviceDispatchAgent', deviceKey, deviceDispatchAgentProps.value)
  }

  const handleDeviceDispatchProject = (deviceKey) => {
    deviceDispatchProjectProps.value.visible = true
    deviceDispatchProjectProps.value.deviceKey = deviceKey
    deviceDispatchProjectProps.value.reload = queryDeviceList
    // console.log('handleDeviceDispatchProject', deviceKey, deviceDispatchProjectProps.value)
  }

  const showConfirmMasterDeviceDialog = (deviceKey) => {
    deviceMasterProps.value.visible = true
    deviceMasterProps.value.deviceKey = deviceKey
    deviceMasterProps.value.reload = queryDeviceList
    // console.log('showConfirmMasterDeviceDialog', deviceKey, deviceMasterProps.value)
  }

  const handleDeviceMaster = () => {
    request.updateDeviceMaster(deviceMasterProps.value.deviceKey).then(res => {
      if (res.success) {
        ElMessage.success(res.message);
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
    // console.log('handleDeviceMaster', deviceMasterProps.value)
    deviceMasterProps.value.visible = false
    deviceMasterProps.value.deviceKey = undefined
    queryDeviceList();
  }

  const handleDeviceChange = (device) => {
    deviceChangeProps.value.visible = true
    deviceChangeProps.value.device = device
    deviceChangeProps.value.reload = queryDeviceList
    // console.log('handleDeviceChange', device, deviceChangeProps.value)
  }

  const handleTuningParameters = (deviceKey) => {
    deviceTuningParameterChangeProps.value.visible = true
    deviceTuningParameterChangeProps.value.deviceKey = deviceKey
    deviceTuningParameterChangeProps.value.reload = queryDeviceList
    // console.log('handleTuningParameters', device, deviceTuningParameterChangeProps.value)
  }

  const resetDevicePwd = (deviceKey) => {
    request.resetDevicePwd(deviceKey).then(res => {
      if (res.success) {
        ElMessage.success(res.message);
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
  }

  const syncDevice = (deviceKey) => {
    request.syncDevice(deviceKey).then(res => {
      if (res.success) {
        ElMessage.success(res.message);
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
  }

</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
</style>
