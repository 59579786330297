<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备详情"
        width="65%"
        @close='closeDialog'
        @open='openDialog'
        >
        <el-descriptions
            :model="devData"
            direction="horizontal"
            :column="2"
            border
        >
          <el-descriptions-item label="设备名称" label-class-name="descriptions-item-label">
            {{ devData.deviceName }}
          </el-descriptions-item>
          <el-descriptions-item label="设备类型" label-class-name="descriptions-item-label">
            {{ devData.deviceTypeName }}
          </el-descriptions-item>
          <el-descriptions-item label="所属代理商" label-class-name="descriptions-item-label">
            {{ devData.agentName }}
          </el-descriptions-item>
          <el-descriptions-item label="所属项目" label-class-name="descriptions-item-label">
            {{ devData.projectName }}
          </el-descriptions-item>
          <el-descriptions-item label="所属设备" label-class-name="descriptions-item-label">
            {{ devData.masterDeviceName }}
          </el-descriptions-item>
          <el-descriptions-item label="网络质量" label-class-name="descriptions-item-label">
            <IconNetwork :networkQuality="devData.networkQuality" :key="new Date().getTime()"/>
          </el-descriptions-item>
          <el-descriptions-item label="音量" label-class-name="descriptions-item-label">
            {{ devData.volume }}
          </el-descriptions-item>
          <el-descriptions-item label="音频档位" label-class-name="descriptions-item-label">
            {{ devData.audioLevel }}&nbsp;档
          </el-descriptions-item>
          <el-descriptions-item label="设备串号" label-class-name="descriptions-item-label">
            {{ devData.deviceSn }}
          </el-descriptions-item>
          <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
            {{ devData.deviceMac }}
          </el-descriptions-item>
          <el-descriptions-item label="最近一次上线时间" label-class-name="descriptions-item-label">
            {{ devData.onlineTime }}
          </el-descriptions-item>
          <el-descriptions-item label="最近一次离线时间" label-class-name="descriptions-item-label">
            {{ devData.offlineTime }}
          </el-descriptions-item>
          <el-descriptions-item label="设备激活时间" label-class-name="descriptions-item-label">
            {{ devData.activateTime }}
          </el-descriptions-item>
          <el-descriptions-item label="设备注册时间" label-class-name="descriptions-item-label">
            {{ devData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item label="SIM卡ICCID信息" label-class-name="descriptions-item-label">
            {{ devData.simIccid }}
          </el-descriptions-item>
          <el-descriptions-item label="设备IMEI信息" label-class-name="descriptions-item-label">
            {{ devData.deviceImei }}
          </el-descriptions-item>
          <el-descriptions-item label="主机主屏幕标题" label-class-name="descriptions-item-label" v-if="devData.deviceType == 1">
            {{ devData.deviceTitle }}
          </el-descriptions-item>
          <el-descriptions-item label="主机主屏幕副标题" label-class-name="descriptions-item-label" v-if="devData.deviceType == 1">
            {{ devData.deviceSubtitle }}
          </el-descriptions-item>
          <el-descriptions-item label="固件版本" label-class-name="descriptions-item-label">
            {{ devData.fwVersion }}
          </el-descriptions-item>
          <el-descriptions-item label="备注" label-class-name="descriptions-item-label">
            {{ devData.remark }}
          </el-descriptions-item>
        </el-descriptions>
        <div :style="{ 'margin-top': '20px' }">
            <el-tabs
                :model="devData"
                type="border-card"
            >
                <el-tab-pane label="通话记录">
                    <div>
                      <el-table
                      :data="devData.callRecords"
                      stripe
                      highlight-current-row
                      :v-loading = "devData.callRecords.length > 0 ? false : true"
                      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                      :cell-style="{'text-align':'center'}"
                      style="width: 100%; margin-top: 10px;"
                      >
                          <el-table-column prop="callDeviceName" label="主叫名称">
                            <template #default="scope">
                              <el-space direction="vertical">
                                <el-text>{{ scope.row.callDeviceName }}</el-text>
                                <el-text>({{ scope.row.callDeviceMac }})</el-text>
                              </el-space>
                            </template>
                          </el-table-column>
                          <el-table-column prop="calledDeviceName" label="被叫名称">
                            <template #default="scope">
                              <el-space direction="vertical">
                                <el-text>{{ scope.row.calledDeviceName }}</el-text>
                                <el-text>{{ scope.row.calledDeviceMac.length > 0 ? '(' + scope.row.calledDeviceMac + ')' : '' }}</el-text>
                              </el-space>
                            </template>
                          </el-table-column>
                          <el-table-column prop="callStateName" label="通话状态" width="165">
                            <template #default="scope">
                              <el-tooltip :content="scope.row.hangupReason" placement="top-start">
                                <el-text>{{ scope.row.callStateName }}</el-text>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="inviteTime" label="呼叫时间" />
                          <el-table-column prop="hangupTime" label="挂断时间" />
                      </el-table>
                      <el-pagination
                        v-model:current-page="pagine.currentPage"
                        v-model:page-size="pagine.pageSize"
                        :small=false
                        :disabled=false
                        :background=true
                        layout="->, total, prev, pager, next"
                        :total="pagine.total"
                        style="margin-top: 10px;"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                      />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="对码设置">
                    <el-table
                    :data="devData.callNumbers"
                    stripe
                    highlight-current-row
                    :v-loading = "devData.callNumbers.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="index" label="序号" width="100">
                          <template #default="scope">
                            第{{ scope.row.index + 1 }}局
                          </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称" />
                        <el-table-column prop="number" label="号码" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="呼叫列表" v-if="devData.deviceType === 0">
                    <el-table
                    :data="devData.targetCallNumbers"
                    stripe
                    highlight-current-row
                    :v-loading = "devData.targetCallNumbers.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="index" label="序号" width="100"/>
                        <el-table-column prop="name" label="名称" />
                        <el-table-column prop="number" label="号码" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="设备事件">
                    <el-table
                    :data="devData.events"
                    stripe
                    highlight-current-row
                    :v-loading = "devData.events.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="createTime" label="时间" width="180"/>
                        <el-table-column prop="event" label="事件" />
                    </el-table>
                    <el-pagination
                        v-model:current-page="eventPagine.currentPage"
                        v-model:page-size="eventPagine.pageSize"
                        :small=false
                        :disabled=false
                        :background=true
                        layout="->, total, prev, pager, next"
                        :total="eventPagine.total"
                        style="margin-top: 10px;"
                        @current-change="handleEventCurrentChange"
                      />
                </el-tab-pane>
            </el-tabs>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';
    import anziotUtils from '@/utils/anziotUtils.js';
    import IconNetwork from '@/components/icon/IconNetwork.vue';

    export default {
        name: 'DialogDeviceDetail',
        props: {
            deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        components: {
          IconNetwork
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const devData = reactive({
                deviceName: '',
                agentName: '',
                projectName: '',
                masterDeviceName: '',
                deviceType: 0,
                deviceTypeName: '',
                deviceSn: '',
                simIccid: '',
                fwVersion: '',
                volume: 0,
                audioLevel: 0,
                networkQuality: 1,
                deviceTitle: '',
                deviceSubtitle: '',
                deviceOnlineStateName: '',
                deviceImei: '',
                onlineTime: '',
                offlineTime: '',
                createTime: '',
                activateTime: '',
                remark: '',
                callNumbers: [],
                targetCallNumbers: [],
                callRecords: [],
                events: []
            });

            const pagine = reactive({
              currentPage: 1,
              pageSize: 10,
              total: 0
            });

            const eventPagine = reactive({
              currentPage: 1,
              pageSize: 10,
              total: 0
            });

            const queryDeviceEvents = () => {
              request.queryDashboardEvents(eventPagine.currentPage, eventPagine.pageSize, props.deviceKey).then(res => {
                devData.events = [];
                if (res.success) {
                    res.data.rows.forEach((item) => {
                      devData.events.push({ event: item.event, createTime: anziotUtils.getTimestampStr(item.time) });
                    });
                    eventPagine.total = res.data.total;
                } else {
                  ElMessage.error('获取系统事件数据失败');
                }
              }).catch(error => {
                  console.log(error)
              });
            }

            const handleEventCurrentChange = (val) => {
              eventPagine.currentPage = val;
              queryDeviceEvents();
            }

            const queryDeviceCallRecordList = () => {
              request.queryCallRecords(pagine.currentPage, pagine.pageSize, { deviceKey: props.deviceKey }).then(res => {
                    if (res.success) {
                        devData.callRecords = [];
                        res.data.rows.forEach((item) => {
                          devData.callRecords.push({
                            callDeviceName: item.callDeviceName,
                            callDeviceNumber: item.callDeviceCallNumber,
                            callDeviceMac: item.callDeviceMac,
                            calledDeviceName: item.calledDeviceName === null ? item.calledDeviceCallNumber : item.calledDeviceName,
                            calledDeviceNumber: item.calledDeviceCallNumber === null ? '' : item.calledDeviceCallNumber,
                            calledDeviceMac: item.calledDeviceMac === null ? '' : item.calledDeviceMac,
                            callStateName: item.callStatusName,
                            hangupReason: item.hangupReason,
                            inviteTime: anziotUtils.getTimestampStr(item.inviteTime),
                            hangupTime: anziotUtils.getTimestampStr(item.hangupTime)
                          });
                        });
                        pagine.total = res.data.total;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            }

            const handleSizeChange = (val) => {
              pagine.pageSize = val;
              queryDeviceCallRecordList();
            }

            const handleCurrentChange = (val) => {
              pagine.currentPage = val;
              queryDeviceCallRecordList();
            }

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        devData.deviceName = device.deviceName;
                        devData.deviceMac = device.deviceMac;
                        devData.agentName = device.agentName;
                        devData.projectName = device.projectName;
                        devData.masterDeviceName = device.masterDeviceName;
                        devData.deviceType = device.deviceType;
                        devData.deviceTypeName = device.deviceTypeName;
                        devData.deviceSn = device.deviceSn;
                        devData.simIccid = device.simIccid;
                        devData.fwVersion = device.fwVersion;
                        devData.volume = device.volume;
                        devData.audioLevel = device.audioLevel;
                        devData.networkQuality = device.networkQuality;
                        devData.deviceTitle = device.deviceTitle;
                        devData.deviceSubtitle = device.deviceSubTitle;
                        devData.deviceOnlineStateName = device.deviceOnlineStateName;
                        devData.deviceImei = device.deviceImei;
                        devData.onlineTime = anziotUtils.getTimestampStr(device.onlineTime);
                        devData.offlineTime = anziotUtils.getTimestampStr(device.offlineTime);
                        devData.createTime = anziotUtils.getTimestampStr(device.createTime);
                        devData.activateTime = anziotUtils.getTimestampStr(device.activateTime);
                        devData.remark = device.remark;
                        devData.callNumbers = device.callNumbers;
                        devData.targetCallNumbers = device.targetCallNumbers;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
                queryDeviceCallRecordList();
                queryDeviceEvents();
                // console.log('DialogDeviceDetail-openDialog', props, devData);
            };

            // console.log('DialogDeviceDetail', props);
            return { IconNetwork, devData, isDialogVisible, pagine, eventPagine, closeDialog, openDialog, handleCurrentChange, handleSizeChange, handleEventCurrentChange };
        }
    };
</script>

<style>
.descriptions-item-label {
    width: 150px;
}
</style>
