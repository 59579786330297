<template>
    <div class="login_container">
    <div class="login_box">
      <div class="login_title">
        <h1>云对讲管理系统</h1>
      </div>
      <el-form label-width="0px" class="login_in" :model="loginForm" ref="loginFormRef">
        <el-form-item prop="user">
          <el-input type="text" v-model="loginForm.user" :prefix-icon="User"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" :prefix-icon="Unlock" show-password @keydown.enter="doNewLogin"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="doNewLogin">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { User, Unlock } from '@element-plus/icons-vue'
  import { ElMessage, ElNotification } from 'element-plus';
  import JSEncrypt from 'jsencrypt'
  import router from '../router';
  import request from '../utils/interface.js';
  import store from '../store/index.js';

  const loginForm = reactive({
    user: '',
    password: ''
  });
  const loginFormRef = ref(null);
  const resetLoginForm = () => {
    loginFormRef.value.resetFields();
  };
  const doNewLogin = () => {
    request.querySystemSecurityCode().then(res => {
       if (res.success) {
        const pk = res.message
        const text = '{"account": "' + loginForm.user + '","password": "' + loginForm.password + '"}';
        const jsrsa = new JSEncrypt();
        jsrsa.setPublicKey(pk);
        const rsatext = jsrsa.encrypt(text);
        const rtext = rsatext.replaceAll('+', ' ')
        const rsaEnText = encodeURIComponent(rtext);
        request.login(rsaEnText).then(res => {
          store.commit('setSystemPK', pk);
          store.commit('setAccount', loginForm.user);
          ElNotification({
            title: '登录成功',
            message: '',
            type: 'success'
          })
          router.push({
            path: '/dashboard'
          });
        });
       } else {
        ElMessage.error('获取登录信息失败');
       }
      }).catch(error => {
        console.log(error)
      });
  }
</script>

<style>
.login_container {
  background-image: url('https://anziot-trial.oss-cn-shenzhen.aliyuncs.com/webapp/9a6039039f9c85990a87979f6452b792.webp');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
}

.login_box {
  width: 450px;
  height: 300px;
  /* background-color: #fff; */
  border-radius: 3px;
  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);

  .login_title {
    height: 60px;
    width: 230px;
    position: absolute;
    left: 45%;
    top: 15%;
    color: white;
    /* background-color: #ffffff; */
    padding: 10px;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0%;
}

.login_in {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 20%;
  padding: 0 0px;
  box-sizing: border-box;
}
</style>
