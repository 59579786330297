<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备编号设置"
        width="650px"
        @close='closeDialog'
        @open='openDialog'
        >
          <div style="margin-top: 10px;">
            <el-descriptions
              :model="deviceInfo"
              direction="horizontal"
              :column="1"
              border
            >
              <el-descriptions-item label="设备名称" label-class-name="descriptions-item-label">
                {{ deviceInfo.deviceName }}
              </el-descriptions-item>
              <el-descriptions-item label="设备类型" label-class-name="descriptions-item-label">
                {{ deviceInfo.deviceType }}
              </el-descriptions-item>
              <el-descriptions-item label="设备IMEI码" label-class-name="descriptions-item-label">
                {{ deviceInfo.deviceImei }}
              </el-descriptions-item>
              <el-descriptions-item label="SIM卡ICCID" label-class-name="descriptions-item-label">
                {{ deviceInfo.simIccid }}
              </el-descriptions-item>
              <el-descriptions-item label="激活时间" label-class-name="descriptions-item-label">
                {{ deviceInfo.activateTime }}
              </el-descriptions-item>
            </el-descriptions>
            <div style="margin-top: 20px;">
              <el-form :model="deviceMacForm" ref="deviceMacFormRef">
                <el-form-item label="设备编号" prop="deviceMac">
                  <el-input type="text" v-model="deviceMacForm.deviceMac" placeholder="请扫描设备上设备编号二维码"/>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" style="margin-top: 15px;" @click="doDeviceMacEdit">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';
    import anziotUtils from '@/utils/anziotUtils.js';

    export default {
        name: 'DialogDeviceMacEdit',
        props: {
          deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceMacForm = reactive({
                deviceKey: '',
                deviceMac: ''
            });

            const deviceInfo = reactive({
              deviceName: '',
              deviceType: '',
              deviceImei: '',
              simIccid: '',
              activateTime: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              // console.log('DialogDeviceMacEdit-openDialog', props.deviceKey, deviceMacForm);
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        deviceMacForm.deviceKey = device.deviceKey;
                        deviceMacForm.deviceMac = device.deviceMac;
                        deviceInfo.deviceName = device.deviceName;
                        deviceInfo.deviceType = device.deviceTypeName;
                        deviceInfo.deviceImei = device.deviceSn;
                        deviceInfo.simIccid = device.simIccid;
                        deviceInfo.activateTime = anziotUtils.getTimestampStr(device.activateTime);
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            };

            const doDeviceMacEdit = () => {
              const mac = deviceMacForm.deviceMac
              if (mac.length > 0 && mac.match(/^[a-z|A-Z]{2}\d{12}$/)) {
                request.updateDeviceMac(props.deviceKey, deviceMacForm.deviceMac).then(res => {
                      if (res.success) {
                          ElMessage.success(res.message);
                          ctx.emit('update:visible', false);
                      } else {
                          ElMessage.error(res.message);
                      }
                  }).catch(error => {
                      console.log(error)
                  });
                // console.log('DialogDeviceMacEdit-DialogDeviceMacEdit', props.deviceKey, deviceMacForm);
              } else {
                ElMessage.error('设备编码错误！格式必须为"两位字母+12位数字"');
              }
            }

            return {
              deviceMacForm,
              deviceInfo,
              isDialogVisible,
              closeDialog,
              openDialog,
              doDeviceMacEdit
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}

.descriptions-item-label {
    width: 150px;
}
</style>
