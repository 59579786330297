<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="项目详情"
        @close='closeDialog'
        @open='openDialog'
        >
        <el-descriptions
            :model="proData"
            direction="horizontal"
            :column="2"
            border
        >
          <el-descriptions-item label="项目名称" label-class-name="descriptions-item-label">
            {{ proData.projectName }}
          </el-descriptions-item>
          <el-descriptions-item label="主机数量" label-class-name="descriptions-item-label">
            {{ proData.deviceHostCount }}
          </el-descriptions-item>
          <el-descriptions-item label="分机数量" label-class-name="descriptions-item-label">
            {{ proData.deviceExtCount }}
          </el-descriptions-item>
          <el-descriptions-item label="总局数" label-class-name="descriptions-item-label">
            {{ proData.callNumberCount }}
          </el-descriptions-item>
          <el-descriptions-item label="项目创建时间" label-class-name="descriptions-item-label">
            {{ proData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item label="最近编辑时间" label-class-name="descriptions-item-label">
            {{ proData.updateTime }}
          </el-descriptions-item>
          <el-descriptions-item label="所属代理商" label-class-name="descriptions-item-label">
            {{ proData.agentName }}
          </el-descriptions-item>
        </el-descriptions>
        <div :style="{ 'margin-top': '20px' }">
            <el-tabs
                :model="proData"
                type="border-card"
            >
                <el-tab-pane label="主机列表">
                    <el-table
                    :data="proData.hostDevices"
                    stripe
                    highlight-current-row
                    :v-loading = "proData.hostDevices.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="deviceName" label="设备名称" />
                        <el-table-column prop="networkQuality" label="在线状态"  width="80">
                            <template #default="scope">
                                <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="simIccid" label="SIM卡" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="分机列表">
                    <el-table
                    :data="proData.extDevices"
                    stripe
                    highlight-current-row
                    :v-loading = "proData.extDevices.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="deviceName" label="设备名称"/>
                        <el-table-column prop="networkQuality" label="在线状态"  width="80">
                            <template #default="scope">
                                <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="simIccid" label="SIM卡" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="管理账号">
                    <el-table
                    :data="proData.accounts"
                    stripe
                    highlight-current-row
                    :v-loading = "proData.accounts.length > 0 ? false : true"
                    :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%; margin-top: 10px;"
                    >
                        <el-table-column prop="nickname" label="账号名称"/>
                        <el-table-column prop="account" label="账号" />
                        <el-table-column prop="createTime" label="创建时间" width="200"/>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';
    import anziotUtils from '../../utils/anziotUtils.js';
    import IconNetwork from '@/components/icon/IconNetwork.vue';

    export default {
        name: 'DialogProjectDetail',
        props: {
            projectKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        components: {
          IconNetwork
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const proData = reactive({
                projectName: '',
                deviceHostCount: 0,
                deviceExtCount: 0,
                callNumberCount: 0,
                updateTime: '',
                createTime: '',
                agentName: '',
                hostDevices: [],
                extDevices: [],
                accounts: []
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
                request.queryProjectDetail(props.projectKey).then(res => {
                    if (res.success) {
                        const project = res.data;
                        proData.projectName = project.projectName;
                        proData.deviceHostCount = project.hostDeviceCount;
                        proData.deviceExtCount = project.extentionCount;
                        proData.callNumberCount = project.callNumberCount;
                        proData.updateTime = anziotUtils.getTimestampStr(project.updateTime);
                        proData.createTime = anziotUtils.getTimestampStr(project.createTime);
                        let agentTree = '';
                        project.agentPath.forEach((item) => {
                            agentTree = agentTree + ' / ' + item;
                        });
                        proData.agentName = agentTree;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
                request.queryDevices(1, 1000, { projectKey: props.projectKey }).then(res => {
                    if (res.success) {
                        proData.hostDevices = [];
                        proData.extDevices = [];
                        res.data.rows.forEach((item) => {
                            if (item.deviceType === 1) {
                                proData.hostDevices.push({
                                    deviceName: item.deviceName,
                                    networkQuality: item.networkQuality,
                                    simIccid: item.simIccid
                                });
                            } else {
                                proData.extDevices.push({
                                    deviceName: item.deviceName,
                                    networkQuality: item.networkQuality,
                                    simIccid: item.simIccid
                                });
                            }
                        });
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
                request.queryAccounts(1, 1000, { projectKey: props.projectKey }).then(res => {
                    if (res.success) {
                        proData.accounts = [];
                        res.data.rows.forEach((item) => {
                            proData.accounts.push({
                                nickname: item.nickname,
                                account: item.account,
                                createTime: anziotUtils.getTimestampStr(item.createTime)
                            });
                        });
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
                // console.log('DialogProjectDetail-openDialog', props, proData);
            };

            // console.log('DialogProjectDetail', props);
            return { IconNetwork, proData, isDialogVisible, closeDialog, openDialog };
        }
    };
</script>

<style>
.descriptions-item-label {
    width: 150px;
}
</style>
