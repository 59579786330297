import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import DeviceView from '../components/DeviceView.vue'
import ProjectView from '../components/ProjectView.vue'
import AccountView from '../components/AccountView.vue'
import DeviceNewView from '../components/DeviceNewView.vue'
import DeviceMatchView from '../components/DeviceMatchView.vue'
import DeviceRemoveView from '../components/DeviceRemoveView.vue'
import DeviceChangeView from '../components/DeviceChangeView.vue'
import DeviceTreeView from '../components/DeviceTreeView.vue'
import DeviceBatchView from '@/components/DeviceBatchView.vue';
import AgentView from '../components/AgentView.vue'
import CallRecordView from '../components/CallRecordView.vue'
import DashboardView from '../components/DashboardView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    children: [
      {
        path: 'dashboard',
        component: DashboardView
      },
      {
        path: 'project',
        component: ProjectView
      },
      {
        path: 'account',
        component: AccountView
      },
      {
        path: 'newcomer',
        component: DeviceNewView
      },
      {
        path: 'remove',
        component: DeviceRemoveView
      },
      {
        path: 'change',
        component: DeviceChangeView
      },
      {
        path: 'list',
        component: DeviceView
      },
      {
        path: 'match',
        component: DeviceMatchView
      },
      {
        path: 'agent',
        component: AgentView
      },
      {
        path: 'callRecord',
        component: CallRecordView
      },
      {
        path: 'dtree',
        component: DeviceTreeView
      },
      {
        path: 'dbatch',
        component: DeviceBatchView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
