<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备更换</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="searchForm.deviceType" class="m-2" placeholder="请选择设备类型">
            <el-option
              v-for="item in [{value: 0, label: '分机'},{value: 1, label: '主机'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="在线状态" prop="onlineState">
          <el-select v-model="searchForm.onlineState" class="m-2" placeholder="请选择设备类型">
            <el-option
              v-for="item in [{value: 0, label: '离线'},{value: 1, label: '在线'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备IMEI" prop="deviceImei">
          <el-input v-model="searchForm.deviceImei" placeholder="请扫描模块上二维码或输入设备IMEI" style="width: 260px" @input="onDeviceIMEIInput"></el-input>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="deviceName" label="设备名称" width="160" show-overflow-tooltip/>
      <el-table-column prop="deviceMac" label="设备编号" width="180"/>
      <el-table-column prop="deviceTypeName" label="设备类型" width="80"/>
      <el-table-column prop="masterDeviceName" label="所属设备"  width="160" show-overflow-tooltip/>
      <el-table-column prop="networkState" label="在线状态" width="80">
        <template #default="scope">
            <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
        </template>
      </el-table-column>
      <el-table-column prop="imei" label="设备IMEI" width="200"/>
      <el-table-column prop="onlineTime" label="最近上线时间" width="165"/>
      <el-table-column prop="offlineTime" label="最近离线时间" width="165"/>
      <el-table-column prop="activationTime" label="激活时间" width="165"/>
      <el-table-column fixed="right" label="操作" width="180">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleDeviceDetail(scope.row.deviceKey)">详情</el-button>
          <el-button link type="primary" size="small" @click="handleDeviceChange(scope.row)">更换设备</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[15, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogDeviceDetail
      v-model:visible="deviceDetailProps.visible"
      v-model:deviceKey="deviceDetailProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceChange
      v-model:visible="deviceChangeProps.visible"
      v-model:device="deviceChangeProps.device"
      v-model:reload="queryDeviceList"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import IconNetwork from '@/components/icon/IconNetwork.vue';
  import DialogDeviceDetail from '@/components/dialog/DialogDeviceDetail.vue';
  import DialogDeviceChange from '@/components/dialog/DialogDeviceChange.vue';

  const tableData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(15);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryDeviceList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryDeviceList();
  }

  const queryDeviceList = () => {
    const queryParam = { isRemoved: false };
    if (anziotUtils.isNotNull(searchForm.deviceName)) {
      queryParam.deviceName = searchForm.deviceName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceType)) {
      queryParam.deviceType = searchForm.deviceType;
    }
    if (anziotUtils.isNotNull(searchForm.onlineState)) {
      queryParam.onlineState = searchForm.onlineState;
    }
    if (anziotUtils.isNotNull(searchForm.deviceImei)) {
      queryParam.deviceImei = searchForm.deviceImei;
    }
    request.queryDevices(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            tableData.value.push({
              deviceKey: item.deviceKey,
              masterDeviceName: item.masterDeviceName,
              projectName: item.projectName,
              agentName: item.agentName,
              deviceName: item.deviceName,
              deviceMac: item.deviceMac,
              deviceType: item.deviceType,
              deviceTypeName: item.deviceTypeName,
              onlineStatus: item.onlineStatus,
              networkQuality: item.networkQuality,
              imei: item.deviceImei,
              callNumberCount: item.callNumberCount,
              onlineTime: anziotUtils.getTimestampStr(item.onlineTime),
              offlineTime: anziotUtils.getTimestampStr(item.offlineTime),
              activationTime: anziotUtils.getTimestampStr(item.activationTime),
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取设备数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  };

  const searchFormRef = ref(null);
  const searchForm = reactive({
    deviceName: '',
    deviceType: '',
    onlineState: '',
    deviceImei: ''
  });

  const onReset = () => {
    searchFormRef.value.resetFields();
    queryDeviceList();
  };

  const onSearch = () => {
    queryDeviceList();
  };

  const onDeviceIMEIInput = () => {
    if (searchForm.deviceImei.length === 0) {
      return
    }
    const charCount = searchForm.deviceImei.split(';').length - 1
    if (charCount !== 6) {
      return
    }
    queryDeviceList();
  }

  onActivated(
    () => {
      queryDeviceList();
    }
  )

  /* 对话框 */
  const deviceDetailProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceChangeProps = ref({ visible: false, device: undefined, reload: () => {} })

  const handleDeviceDetail = (deviceKey) => {
    deviceDetailProps.value.visible = true
    deviceDetailProps.value.deviceKey = deviceKey
    deviceDetailProps.value.reload = queryDeviceList
    // console.log('handleDeviceDetail', deviceKey, deviceDetailProps.value)
  }

  const handleDeviceChange = (device) => {
    deviceChangeProps.value.visible = true
    deviceChangeProps.value.device = device
    deviceChangeProps.value.reload = queryDeviceList
    // console.log('handleDeviceChange', device, deviceChangeProps.value)
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
</style>
