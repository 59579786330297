<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>新入网设备</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="11" :offset="1">
        <div style="margin-top: 20px; width: 550px">
          <el-form label-width="150px" style="margin-top: 20px" :model="deviceBindForm" ref="deviceBindFormRef">
            <el-form-item prop="deviceNo" label="设备编号">
              <el-input type="text" v-model="deviceBindForm.deviceNo" placeholder="请扫描模块上二维码或输入设备编号" clearable @input="deviceBindForm.deviceNo = deviceBindForm.deviceNo.replace(/\s+/g,'')"></el-input>
            </el-form-item>
            <el-form-item prop="bindCode" label="设备播放四位绑定码">
              <el-input type="text" v-model="deviceBindForm.bindCode" clearable maxlength="4" @input="deviceBindForm.bindCode = deviceBindForm.bindCode.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item prop="partLimit" label="通话局数限制">
                <el-input-number v-model="deviceBindForm.partLimit" :min="1" :max="4" />
              </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doDeviceBind">绑定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <el-result
            :icon="deviceBindInfoForm.information ? 'success' : 'info'"
            :title="deviceBindInfoForm.information"
            :sub-title="deviceBindInfoForm.information ? '上个设备绑定结果' : '尚未操作激活设备'"
          />
        </div>
      </el-col>
    </el-row>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="设备IMEI" prop="deviceImei">
          <el-input v-model="searchForm.deviceImei" placeholder="请扫描模块上二维码或输入设备IMEI" style="width: 260px" @input="onDeviceIMEIInput" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备SIM" prop="simIccid">
          <el-input v-model="searchForm.simIccid" placeholder="请输入设备SIM卡ICCID码" style="width: 260px" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceMac">
          <el-input v-model="searchForm.deviceMac" placeholder="请输入设备编号" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="在线状态" prop="onlineState">
          <el-select v-model="searchForm.onlineState" class="m-2" placeholder="请选择设备在线状态" clearable>
            <el-option
              v-for="item in [{value: 0, label: '离线'},{value: 1, label: '在线'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" style="width: 260px" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="searchForm.deviceType" class="m-2" placeholder="请选择设备类型" style="width: 260px" clearable>
            <el-option
              v-for="item in [{value: 1, label: '主机'},{value: 0, label: '分机'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
          <el-button type="success" @click="createVirtualDevice">创建虚拟主机</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip/>
      <el-table-column prop="deviceMac" label="设备编号" width="160">
        <template #default="scope">
          <el-button link @click="handleDeviceMacEdit(scope.row.deviceKey)">{{ null == scope.row.deviceMac ? '—' : scope.row.deviceMac }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="deviceTypeName" label="设备类型" width="80"/>
      <el-table-column prop="agentName" label="所属代理商" show-overflow-tooltip/>
      <el-table-column prop="networkState" label="在线状态" width="80">
        <template #default="scope">
            <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
        </template>
      </el-table-column>
      <el-table-column prop="imei" label="设备IMEI" width="200"/>
      <el-table-column prop="onlineTime" label="最近上线时间" width="165"/>
      <el-table-column prop="activationTime" label="激活时间" width="165"/>
      <el-table-column fixed="right" label="操作" width="230">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleDeviceDispatch(scope.row.deviceKey)">分配代理商</el-button>
          <el-button link type="primary" size="small" @click="handleDeviceDetail(scope.row.deviceKey)">详情</el-button>
          <el-button link type="primary" size="small" @click="handleDeviceEdit(scope.row.deviceKey)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[15, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogDeviceDispatchAgent
      v-model:visible="deviceDispatchProps.visible"
      v-model:deviceKey="deviceDispatchProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceDetail
      v-model:visible="deviceDetailProps.visible"
      v-model:deviceKey="deviceDetailProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceEdit
      v-model:visible="deviceEditProps.visible"
      v-model:deviceKey="deviceEditProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceMacEdit
      v-model:visible="deviceMacEditProps.visible"
      v-model:deviceKey="deviceMacEditProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <DialogDeviceBind
      v-model:visible="deviceBindProps.visible"
      v-model:reload="queryDeviceList"
    />
    <DialogVirtualDeviceCreate
      v-model:visible="virtualDeviceCreateProps.visible"
      v-model:reload="queryDeviceList"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import IconNetwork from '@/components/icon/IconNetwork.vue';
  import DialogDeviceDetail from '@/components/dialog/DialogDeviceDetail.vue';
  import DialogDeviceEdit from '@/components/dialog/DialogDeviceEdit.vue';
  import DialogDeviceDispatchAgent from '@/components/dialog/DialogDeviceDispatchAgent.vue';
  import DialogDeviceMacEdit from '@/components/dialog/DialogDeviceMacEdit.vue';
  import DialogDeviceBind from '@/components/dialog/DialogDeviceBind.vue';
  import DialogVirtualDeviceCreate from '@/components/dialog/DialogVirtualDeviceCreate.vue';

  // Bind
  const deviceBindInfoForm = reactive({
      information: ''
  });

  const deviceBindFormRef = ref(null);
  const deviceBindForm = reactive({
    deviceNo: '',
    bindCode: '',
    partLimit: 4
  });

  const doDeviceBind = () => {
  const bindParam = {
    deviceNo: deviceBindForm.deviceNo,
    bindCode: deviceBindForm.bindCode,
    partLimit: deviceBindForm.partLimit
  };
  request.bindDevice(bindParam).then(res => {
      if (res.success) {
        ElMessage.success('设备编码绑定成功');
        deviceBindInfoForm.information = res.data
        deviceBindFormRef.value.resetFields();
        queryDeviceList();
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
    // console.log('DialogDeviceBind-doDeviceBind', deviceBindForm);
  }

  const tableData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(10);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryDeviceList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryDeviceList();
  }

  const queryDeviceList = () => {
    const queryParam = { isNew: true, isRemoved: false };
    if (anziotUtils.isNotNull(searchForm.deviceMac)) {
      queryParam.deviceMac = searchForm.deviceMac;
    }
    if (anziotUtils.isNotNull(searchForm.deviceImei)) {
      queryParam.deviceImei = searchForm.deviceImei;
    }
    if (anziotUtils.isNotNull(searchForm.simIccid)) {
      queryParam.simIccid = searchForm.simIccid;
    }
    if (anziotUtils.isNotNull(searchForm.onlineState)) {
      queryParam.onlineState = searchForm.onlineState;
    }
    if (anziotUtils.isNotNull(searchForm.deviceName)) {
      queryParam.deviceName = searchForm.deviceName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceType)) {
      queryParam.deviceType = searchForm.deviceType;
    }
    request.queryDevices(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            tableData.value.push({
              deviceKey: item.deviceKey,
              masterDeviceName: item.masterDeviceName,
              projectName: item.projectName,
              agentName: item.agentName,
              deviceName: item.deviceName,
              deviceMac: item.deviceMac,
              deviceType: item.deviceType,
              deviceTypeName: item.deviceTypeName,
              onlineStatus: item.onlineStatus,
              networkQuality: item.networkQuality,
              imei: item.deviceImei,
              simIccid: item.simIccid,
              callNumberCount: item.callNumberCount,
              activationTime: anziotUtils.getTimestampStr(item.activationTime),
              onlineTime: anziotUtils.getTimestampStr(item.onlineTime),
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取设备数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  };

  const searchFormRef = ref(null);
  const searchForm = reactive({
    deviceName: '',
    deviceType: '',
    deviceImei: '',
    simIccid: '',
    onlineState: ''
  });

  const onReset = () => {
    searchFormRef.value.resetFields();
    queryDeviceList();
  };

  const onSearch = () => {
    queryDeviceList();
  };

  const createVirtualDevice = () => {
    virtualDeviceCreateProps.value.visible = true
    virtualDeviceCreateProps.value.reload = queryDeviceList
    console.log('createVirtualDevice')
  };

  const onDeviceIMEIInput = () => {
    if (searchForm.deviceImei.length === 0) {
      return
    }
    const charCount = searchForm.deviceImei.split(';').length - 1
    if (charCount !== 6) {
      return
    }
    queryDeviceList();
  }

  onActivated(
    () => {
      deviceBindFormRef.value.resetFields();
      queryDeviceList();
    }
  )

  /* 对话框 */
  const deviceBindProps = ref({ visible: false })
  const deviceDispatchProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceDetailProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceEditProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const deviceMacEditProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })
  const virtualDeviceCreateProps = ref({ visible: false, reload: () => {} })

  // const handleDeviceBind = () => {
  //   deviceBindProps.value.visible = true
  //   deviceBindProps.value.reload = queryDeviceList
  //   console.log('handleDeviceBind', deviceBindProps.value)
  // };

  const handleDeviceDispatch = (deviceKey) => {
    deviceDispatchProps.value.visible = true
    deviceDispatchProps.value.deviceKey = deviceKey
    deviceDispatchProps.value.reload = queryDeviceList
    // console.log('handleDeviceDispatch', deviceKey, deviceDispatchProps.value)
  }

  const handleDeviceDetail = (deviceKey) => {
    deviceDetailProps.value.visible = true
    deviceDetailProps.value.deviceKey = deviceKey
    deviceDetailProps.value.reload = queryDeviceList
    // console.log('handleDeviceDetail', deviceKey, deviceDetailProps.value)
  }

  const handleDeviceEdit = (deviceKey) => {
    deviceEditProps.value.visible = true
    deviceEditProps.value.deviceKey = deviceKey
    deviceEditProps.value.reload = queryDeviceList
    // console.log('handleDeviceEdit', deviceKey, deviceEditProps.value)
  }

  const handleDeviceMacEdit = (deviceKey) => {
    deviceMacEditProps.value.visible = true
    deviceMacEditProps.value.deviceKey = deviceKey
    deviceMacEditProps.value.reload = queryDeviceList
    // console.log('handleDeviceMacEdit', deviceKey, deviceMacEditProps.value)
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
