<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备音频参数设置"
        width="750px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-descriptions
              :model="deviceTuningParameterEditForm"
              direction="horizontal"
              :column="1"
              border
          >
            <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
              {{ deviceTuningParameterEditForm.deviceMac }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 20px; width: 100%">
            <el-form label-width="130px" :model="deviceTuningParameterEditForm" ref="deviceTuningParameterEditFormRef">
                <div v-if="deviceTuningParameterEditForm.deviceType == 0">
                    <el-form-item prop="sp0" label="麦克风底噪阈值">
                        <div style="width: 100%">
                            <el-row>
                                <el-col :span=7>
                                    <el-input-number v-model="deviceTuningParameterEditForm.sp0" :min="10" :max="10000" />
                                </el-col>
                                <el-col :span=17>
                                    <el-text style="margin-left: 5px;">（值越大主机听到分机的底噪越少，但越容易卡顿）</el-text>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span=24>
                                    <el-text type="danger" style="margin-left: 10px;">麦克风底噪阈值建议：1000</el-text>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-form-item prop="rt0" label="扬声器底噪阈值">
                        <div style="width: 100%">
                            <el-row>
                                <el-col :span=7>
                                    <el-input-number v-model="deviceTuningParameterEditForm.rt0" :min="1" :max="10000" />
                                </el-col>
                                <el-col :span=17>
                                    <el-text style="margin-left: 5px;">（值越大分机听到主机的底噪越少，但听主机说话越容易卡顿）</el-text>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span=24>
                                    <el-text type="danger" style="margin-left: 10px;">扬声器底噪阈值建议：1000</el-text>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-form-item prop="ga0" label="语音增益">
                        <div style="width: 100%">
                            <el-row>
                                <el-col :span=7>
                                    <el-input-number v-model="deviceTuningParameterEditForm.ga0" :min="-100000" :max="100000" />
                                </el-col>
                                <el-col :span=17>
                                    <el-text style="margin-left: 10px;">（正值增大麦克风收音音量，负值降低麦克风收音音量）</el-text>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span=24>
                                    <el-text type="danger" style="margin-left: 10px;">语音增益建议：0</el-text>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-form-item prop="vmic0" label="麦克风音量补齐">
                        <div style="width: 100%">
                            <el-switch
                                class="volumeAutoAdjustSwitch"
                                active-text="开启"
                                inactive-text="关闭"
                                :active-value=1
                                :inactive-value=0
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                v-model="deviceTuningParameterEditForm.vmic0"
                            />
                        </div>
                    </el-form-item>
                    <el-form-item prop="vspk0" label="扬声器音量补齐">
                        <div style="width: 100%">
                            <el-switch
                                class="volumeAutoAdjustSwitch"
                                active-text="开启"
                                inactive-text="关闭"
                                :active-value=1
                                :inactive-value=0
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                v-model="deviceTuningParameterEditForm.vspk0"
                            />
                        </div>
                    </el-form-item>
                </div>
                <div v-if="deviceTuningParameterEditForm.deviceType == 1">
                    <el-tabs :value="getTabsDefault()">
                        <el-tab-pane label="外置麦克风" name="first">
                            <div>
                                <el-form-item prop="sp0" label="通话距离灵敏度">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.sp0" :min="10" :max="100000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（值越大，灵敏度越低，通话时嘴距离麦克风需要越近）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">通话距离灵敏度建议设置：450</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <!-- <el-form-item prop="ga0" label="语音增益">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.ga0" :min="-100000" :max="100000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（正值增大麦克风收音音量，负值降低麦克风收音音量）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">语音增益建议设置：0</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item> -->
                                <el-form-item prop="rt0" label="啸叫抑制">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.rt0" :min="1" :max="1000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（值越小抑制啸叫效果越明显，过小的值会产生卡顿）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">啸叫抑制建议设置：35</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="手柄麦克风" name="second">
                            <div>
                                <el-form-item prop="sp1" label="通话距离灵敏度">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.sp1" :min="10" :max="100000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（值越大，灵敏度越低，通话时嘴距离麦克风需要越近）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">通话距离灵敏度建议设置：400</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <!-- <el-form-item prop="ga1" label="语音增益">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.ga1" :min="-100000" :max="100000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（正值增大麦克风收音音量，负值降低麦克风收音音量）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">语音增益：2线通话器建议：0；4线通话器建议：-80</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item> -->
                                <el-form-item prop="rt1" label="啸叫抑制">
                                    <el-row>
                                        <el-col :span=7>
                                            <el-input-number v-model="deviceTuningParameterEditForm.rt1" :min="1" :max="1000" />
                                        </el-col>
                                        <el-col :span=17>
                                            <el-text style="margin-left: 10px;">（值越小抑制啸叫效果越明显，过小的值会产生卡顿）</el-text>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span=24>
                                            <el-text type="danger" style="margin-left: 10px;">啸叫抑制建议设置：55</el-text>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <el-form-item style="margin-top: 20px;">
                    <el-button type="primary" @click="doDeviceTuningParameterUpdate">保存</el-button>
                </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { computed, reactive, ref } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';

    export default {
        name: 'DialogDeviceTuningParameterUpdate',
        props: {
            deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceTuningParameterEditFormRef = ref({});
            const deviceTuningParameterEditForm = reactive({
                deviceKey: props.deviceKey,
                deviceName: '',
                deviceMac: '',
                deviceType: 0,
                sp0: -1,
                rt0: -1,
                ga0: 0,
                vmic0: -1,
                vspk0: -1,
                sp1: -1,
                rt1: -1,
                ga1: 0
            });

            const closeDialog = () => {
                deviceTuningParameterEditFormRef.value.resetFields();
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
                request.queryDeviceDetail(props.deviceKey).then(res => {
                        if (res.success) {
                            const device = res.data;
                            deviceTuningParameterEditForm.deviceKey = device.deviceKey;
                            deviceTuningParameterEditForm.deviceType = device.deviceType;
                            deviceTuningParameterEditForm.deviceMac = device.deviceMac;
                            deviceTuningParameterEditForm.deviceName = device.deviceName;
                            if ('tuningParameters' in device && device.tuningParameters.length > 0) {
                                if (device.tuningParameters.length > 1) {
                                    for (let i = 0; i < device.tuningParameters.length; i++) {
                                        if (device.tuningParameters[i].type === 0) {
                                            deviceTuningParameterEditForm.sp0 = device.tuningParameters[i].sp;
                                            deviceTuningParameterEditForm.rt0 = device.tuningParameters[i].rt;
                                            deviceTuningParameterEditForm.ga0 = device.tuningParameters[i].ga;
                                        } else {
                                            deviceTuningParameterEditForm.sp1 = device.tuningParameters[i].sp;
                                            deviceTuningParameterEditForm.rt1 = device.tuningParameters[i].rt;
                                            deviceTuningParameterEditForm.ga1 = device.tuningParameters[i].ga;
                                        }
                                    }
                                } else {
                                    deviceTuningParameterEditForm.sp0 = device.tuningParameters[0].sp;
                                    deviceTuningParameterEditForm.rt0 = device.tuningParameters[0].rt;
                                    deviceTuningParameterEditForm.ga0 = device.tuningParameters[0].ga;
                                    deviceTuningParameterEditForm.vmic0 = device.tuningParameters[0].vmic;
                                    deviceTuningParameterEditForm.vspk0 = device.tuningParameters[0].vspk;
                                }
                            }
                        } else {
                            ElMessage.error(res.message);
                        }
                    }).catch(error => {
                        console.log(error)
                    });
            //   console.log('DialogDeviceEdit-openDialog', props.deviceKey, deviceTuningParameterEditForm);
            };

            const doDeviceTuningParameterUpdate = () => {
              if (!deviceTuningParameterEditForm.deviceKey) {
                ElMessage.error('设备信息错误！');
                return
              }
              const updateParam = {
                tunningParameters: []
              };
              if (deviceTuningParameterEditForm.sp0 !== -1) {
                updateParam.tunningParameters[0] = {
                    type: 0,
                    sp: deviceTuningParameterEditForm.sp0,
                    rt: deviceTuningParameterEditForm.rt0,
                    ga: deviceTuningParameterEditForm.ga0,
                    vmic: deviceTuningParameterEditForm.vmic0,
                    vspk: deviceTuningParameterEditForm.vspk0
                }
              }
              if (deviceTuningParameterEditForm.deviceType === 1) {
                  if (deviceTuningParameterEditForm.sp1 !== -1) {
                    updateParam.tunningParameters[1] = {
                        type: 1,
                        sp: deviceTuningParameterEditForm.sp1,
                        rt: deviceTuningParameterEditForm.rt1,
                        ga: deviceTuningParameterEditForm.ga1
                    }
                  }
              }
              request.updateDeviceTuningParameter(props.deviceKey, updateParam).then(res => {
                if (res.success) {
                  ElMessage.success('设备音频参数设置成功');
                  ctx.emit('update:visible', false);
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogDeviceChange-doDeviceChange', props, changeParam);
            }

            const getTabsDefault = () => {
                return 'first';
            }

            return {
                getTabsDefault,
                deviceTuningParameterEditForm,
                deviceTuningParameterEditFormRef,
                isDialogVisible,
                closeDialog,
                openDialog,
                doDeviceTuningParameterUpdate
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.volumeAutoAdjustSwitch .el-switch__label {
    position: absolute;
    display: none;
    color: #000;
}
/*打开时文字位置设置*/
.volumeAutoAdjustSwitch .el-switch__label--right {
    z-index: 1;
    right: -10px;    /*不同场景下可能不同，自行调整*/
    color: #fff;
}
/*关闭时文字位置设置*/
.volumeAutoAdjustSwitch .el-switch__label--left {
    z-index: 1;
    left: 23px;    /*不同场景下可能不同，自行调整*/
    color: #fff;
}
/*显示文字*/
.volumeAutoAdjustSwitch .el-switch__label.is-active {
    display: block;
    color: #fff;
}
.volumeAutoAdjustSwitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
    width: 60px !important;    /*开关按钮的宽度大小*/
}
</style>
