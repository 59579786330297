<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="创建账号"
        width="600px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-text type="danger" size="large" style="margin-left: 160px;">为代理商【{{ parentAgentName }}】创建账号</el-text>
          <el-form label-width="150px" :model="accountCreateForm" ref="accountCreateFormRef" style="margin-top: 15px">
              <el-form-item prop="nickname" label="账号名称（必填）">
                <el-input type="text" v-model="accountCreateForm.nickname"></el-input>
              </el-form-item>
              <el-form-item prop="account" label="登录账号（必填）">
                <el-input type="text" v-model="accountCreateForm.account"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="doAccountCreate" style="margin-top: 30px;">创建</el-button>
              </el-form-item>
            </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogAccountCreate',
        props: {
          agentKey: {
              type: String,
              default: undefined
          },
          agentName: {
              type: String,
              default: undefined
          },
          visible: {
              type: Boolean,
              default: false
          },
          reload: {
            type: Function,
            default: () => null
          }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });
            const parentAgentKey = computed({
                get() {
                    return props.agentKey
                },
                set(val) {
                    return val
                }
            });
            const parentAgentName = computed({
                get() {
                    return props.agentName
                },
                set(val) {
                    return val
                }
            });

            const accountCreateForm = reactive({
                nickname: '',
                agentKey: '',
                account: ''
            });

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              accountCreateForm.nickname = undefined;
              accountCreateForm.account = undefined;
              accountCreateForm.agentKey = undefined;
              // console.log('DialogAccountCreate-openDialog', props, accountCreateForm);
            };

            const doAccountCreate = () => {
              if (!accountCreateForm.nickname || accountCreateForm.nickname.trim().length < 1) {
                ElMessage.error('请输入账号名称');
                return
              }
              if (!accountCreateForm.account || accountCreateForm.account.trim().length < 1) {
                ElMessage.error('请输入登录账号');
                return
              }
              const createParam = {
                nickname: accountCreateForm.nickname.trim(),
                account: accountCreateForm.account.trim(),
                agentKey: props.agentKey
              };
              request.createAccount(createParam).then(res => {
                if (res.success) {
                  ElMessage.success('账号创建成功');
                  ctx.emit('update:visible', false);
                  props.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogAccountCreate-doAccountCreate', props, accountCreateForm);
            }

            const refreshData = (val) => {
              accountCreateForm.agentKey = val;
            }

            const loadAgentList = (param) => {
              const queryParam = { agentName: '', isOnlyAgent: true };
              if (typeof(param) === 'string') {
                queryParam.agentName = param;
              }
              loadingAgentListQuery.value = true
                setTimeout(() => {
                  request.queryAgents(1, 10, queryParam).then(res => {
                    if (res.success) {
                      agentList.value = [];
                        res.data.rows.forEach((item) => {
                          agentList.value.push({
                            agentKey: item.agentKey,
                            agentName: item.agentName
                          });
                        });
                    } else {
                      ElMessage.error('获取代理商数据失败');
                    }
                  }).catch(error => {
                      console.log(error)
                  });
                  loadingAgentListQuery.value = false
                }, 300)
            }

            return {
              parentAgentKey,
              parentAgentName,
              accountCreateForm,
              agentList,
              isDialogVisible,
              loadingAgentListQuery,
              closeDialog,
              openDialog,
              doAccountCreate,
              refreshData,
              loadAgentList
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
