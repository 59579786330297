<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="新建代理商"
        width="600px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-text type="danger" size="large" style="margin-left: 160px;">当前代理商：{{ parentAgentName }}</el-text>
          <el-form label-width="200px" :model="agentCreateForm" ref="agentCreateFormRef" style="margin-top: 30px;">
            <el-form-item prop="agentName" label="下级代理商名称（必填）">
              <el-input type="text" v-model="agentCreateForm.agentName"></el-input>
            </el-form-item>
            <el-form-item prop="account" label="下级代理商管理账号（必填）">
              <el-input type="text" v-model="agentCreateForm.account"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doAgentCreate" style="margin-top: 30px;">创建</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogAgentCreate',
        props: {
            agentKey: {
                type: String,
                default: undefined
            },
            agentName: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const parentAgentKey = computed({
                get() {
                    return props.agentKey
                },
                set(val) {
                    return val
                }
            });
            const parentAgentName = computed({
                get() {
                    return props.agentName
                },
                set(val) {
                    return val
                }
            });
            const agentCreateForm = reactive({
                agentName: '',
                account: ''
            });

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              agentCreateForm.agentName = undefined;
              agentCreateForm.account = undefined;
              // console.log('DialogAgentCreate-openDialog', props, agentCreateForm);
            };

            const doAgentCreate = () => {
              if (!agentCreateForm.agentName || agentCreateForm.agentName.trim().length < 1) {
                ElMessage.error('请输入代理商名称');
                return
              }
              if (!agentCreateForm.account || agentCreateForm.account.trim().length < 1) {
                ElMessage.error('请输入登录账号');
                return
              }
              const targetAgentKey = props.agentKey;
              const createParam = {
                parentAgentKey: targetAgentKey,
                agentName: agentCreateForm.agentName,
                account: agentCreateForm.account
              };
              request.createAgent(createParam).then(res => {
                if (res.success) {
                  ElMessage.success('代理商创建成功');
                  ctx.emit('update:visible', false);
                  props.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogAgentCreate-doAgentCreate', props, agentCreateForm);
            }

            const refreshData = (val) => {
              agentCreateForm.parentAgentKey = val;
            }

            const loadAgentList = (param) => {
              const queryParam = { agentName: '', isOnlyAgent: true };
              if (typeof(param) === 'string') {
                queryParam.agentName = param;
              }
              loadingAgentListQuery.value = true
                setTimeout(() => {
                  request.queryAgents(1, 10, queryParam).then(res => {
                    if (res.success) {
                      agentList.value = [];
                        res.data.rows.forEach((item) => {
                          agentList.value.push({
                            agentKey: item.agentKey,
                            agentName: item.agentName
                          });
                        });
                    } else {
                      ElMessage.error('获取代理商数据失败');
                    }
                  }).catch(error => {
                      console.log(error)
                  });
                  loadingAgentListQuery.value = false
                }, 300)
            }

            return {
              parentAgentKey,
              parentAgentName,
              agentCreateForm,
              agentList,
              isDialogVisible,
              loadingAgentListQuery,
              closeDialog,
              openDialog,
              doAgentCreate,
              refreshData,
              loadAgentList
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
