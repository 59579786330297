<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="通话详情"
        width="65%"
        @close='closeDialog'
        @open='openDialog'
        >
        <el-descriptions
            :model="callRecordData"
            direction="horizontal"
            :column="2"
            border
        >
          <el-descriptions-item label="主叫名称" label-class-name="descriptions-item-label">
            {{ callRecordData.callName }}
          </el-descriptions-item>
          <el-descriptions-item label="被叫名称" label-class-name="descriptions-item-label">
            {{ callRecordData.calledName }}
          </el-descriptions-item>
          <el-descriptions-item label="主叫号码" label-class-name="descriptions-item-label">
            {{ callRecordData.callNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="被叫号码" label-class-name="descriptions-item-label">
            {{ callRecordData.calledNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="主叫设备编号" label-class-name="descriptions-item-label">
            {{ callRecordData.callDeviceMac }}
          </el-descriptions-item>
          <el-descriptions-item label="被叫设备编号" label-class-name="descriptions-item-label">
            {{ callRecordData.calledDeviceMac }}
          </el-descriptions-item>
          <el-descriptions-item label="主叫设备类型" label-class-name="descriptions-item-label">
            {{ callRecordData.callDeviceType }}
          </el-descriptions-item>
          <el-descriptions-item label="被叫设备类型" label-class-name="descriptions-item-label">
            {{ callRecordData.calledDeviceType }}
          </el-descriptions-item>
          <el-descriptions-item label="通话状态" label-class-name="descriptions-item-label">
            {{ callRecordData.status }}
          </el-descriptions-item>
          <el-descriptions-item label="挂断原因" label-class-name="descriptions-item-label">
            {{ callRecordData.hangupReason }}
          </el-descriptions-item>
          <el-descriptions-item label="通话起始时间" label-class-name="descriptions-item-label">
            {{ callRecordData.startTime }}
          </el-descriptions-item>
          <el-descriptions-item label="通话结束时间" label-class-name="descriptions-item-label">
            {{ callRecordData.endTime }}
          </el-descriptions-item>
          <el-descriptions-item label="呼叫时间" label-class-name="descriptions-item-label">
            {{ callRecordData.inviteTime }}
          </el-descriptions-item>
          <el-descriptions-item label="应答时间" label-class-name="descriptions-item-label">
            {{ callRecordData.answerTime }}
          </el-descriptions-item>
          <el-descriptions-item label="挂断时间" label-class-name="descriptions-item-label">
            {{ callRecordData.hangupTime }}
          </el-descriptions-item>
          <el-descriptions-item label="创建时间" label-class-name="descriptions-item-label">
            {{ callRecordData.createTime }}
          </el-descriptions-item>
        </el-descriptions>
        <div :style="{ 'margin-top': '20px' }">
          <el-table
            :data="callRecordData.processes"
            stripe
            highlight-current-row
            :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%; margin-top: 10px;"
          >
            <el-table-column prop="timestamp" label="时间" width="165"/>
            <el-table-column prop="description" label="动作" />
          </el-table>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';
    import anziotUtils from '@/utils/anziotUtils.js';

    export default {
        name: 'DialogCallRecordDetail',
        props: {
            callRecordKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const callRecordData = reactive({
                callName: '',
                calledName: '',
                callNumber: '',
                calledNumber: '',
                callDeviceMac: '',
                calledDeviceMac: '',
                callDeviceType: '',
                calledDeviceType: '',
                status: '',
                hangupReason: '',
                startTime: '',
                endTime: '',
                inviteTime: '',
                answerTime: '',
                hangupTime: '',
                createTime: '',
                processes: []
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              request.queryCallRecordDetail(props.callRecordKey).then(res => {
                    if (res.success) {
                        callRecordData.processes = []
                        const callRecord = res.data;
                        callRecordData.callName = callRecord.callDeviceName;
                        callRecordData.calledName = callRecord.calledDeviceName;
                        callRecordData.callNumber = callRecord.callDeviceCallNumber;
                        callRecordData.calledNumber = callRecord.calledDeviceCallNumber;
                        callRecordData.callDeviceMac = callRecord.callDeviceMac;
                        callRecordData.calledDeviceMac = callRecord.calledDeviceMac;
                        callRecordData.callDeviceType = callRecord.callDeviceTypeName;
                        callRecordData.calledDeviceType = callRecord.calledDeviceTypeName;
                        callRecordData.status = callRecord.callStatusName;
                        callRecordData.hangupReason = callRecord.hangupReason;
                        callRecordData.startTime = anziotUtils.getTimestampStr(callRecord.startTime);
                        callRecordData.endTime = anziotUtils.getTimestampStr(callRecord.endTime);
                        callRecordData.inviteTime = anziotUtils.getTimestampStr(callRecord.inviteTime);
                        callRecordData.answerTime = anziotUtils.getTimestampStr(callRecord.answerTime);
                        callRecordData.hangupTime = anziotUtils.getTimestampStr(callRecord.hangupTime);
                        callRecordData.createTime = anziotUtils.getTimestampStr(callRecord.createTime);
                        callRecord.process.forEach((item) => {
                          callRecordData.processes.push({
                            timestamp: anziotUtils.getTimestampStr(item.timestamp),
                            description: item.description
                          });
                        });
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            };
            return { callRecordData, isDialogVisible, closeDialog, openDialog };
        }
    };
</script>

<style>
.descriptions-item-label {
    width: 150px;
}
</style>
