<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备对码设置"
        width="650px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-descriptions
            :model="deviceMatchForm"
            direction="horizontal"
            :column="1"
            border
          >
            <el-descriptions-item label="设备名称" label-class-name="descriptions-item-label">
              {{ deviceMatchForm.deviceName }}
            </el-descriptions-item>
            <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
              {{ deviceMatchForm.deviceMac }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 20px;">
            <el-form :model="deviceMatchForm" ref="deviceMatchFormRef">
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="callNumber1">
                    <el-input type="text" v-model="deviceMatchForm.callNumber1" placeholder="如不输入，系统将自动生成" style="margin-left: 5px" maxlength="5" @input="deviceMatchForm.callNumber1 = deviceMatchForm.callNumber1.replace(/[^\d]/g,'')">
                      <template #prepend>编号1</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="elevator1">
                    <el-input type="text" v-model="deviceMatchForm.elevator1">
                      <template #prepend>TTS1&nbsp;</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item prop="callNumber2">
                    <el-input type="text" v-model="deviceMatchForm.callNumber2" placeholder="如不输入，系统将自动生成" style="margin-left: 5px" maxlength="5" @input="deviceMatchForm.callNumber2 = deviceMatchForm.callNumber2.replace(/[^\d]/g,'')">
                      <template #prepend>编号2</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="elevator2">
                    <el-input type="text" v-model="deviceMatchForm.elevator2">
                      <template #prepend>TTS2&nbsp;</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item prop="callNumber3">
                    <el-input type="text" v-model="deviceMatchForm.callNumber3" placeholder="如不输入，系统将自动生成" style="margin-left: 5px" maxlength="5" @input="deviceMatchForm.callNumber3 = deviceMatchForm.callNumber3.replace(/[^\d]/g,'')">
                      <template #prepend>编号3</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="elevator3">
                    <el-input type="text" v-model="deviceMatchForm.elevator3">
                      <template #prepend>TTS3&nbsp;</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item prop="callNumber4">
                    <el-input type="text" v-model="deviceMatchForm.callNumber4" placeholder="如不输入，系统将自动生成" style="margin-left: 5px" maxlength="5" @input="deviceMatchForm.callNumber4 = deviceMatchForm.callNumber4.replace(/[^\d]/g,'')">
                      <template #prepend>编号4</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="elevator4">
                    <el-input type="text" v-model="deviceMatchForm.elevator4">
                      <template #prepend>TTS4&nbsp;</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item>
                <el-button type="primary" @click="doDeviceMatch">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';
    import anziotUtils from '@/utils/anziotUtils.js';

    export default {
        name: 'DialogDeviceMatch',
        props: {
          deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceMatchFormRef = ref();
            const deviceMatchForm = reactive({
                deviceKey: '',
                deviceName: '',
                callNumber1: '',
                callNumber2: '',
                callNumber3: '',
                callNumber4: '',
                elevator1: '',
                elevator2: '',
                elevator3: '',
                elevator4: ''
            });

            const closeDialog = () => {
              deviceMatchFormRef.value.resetFields();
              ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              // console.log('DialogDeviceMatch-openDialog', props.deviceKey, deviceMatchForm);
              deviceMatchFormRef.value.resetFields();
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        deviceMatchForm.deviceKey = device.deviceKey;
                        deviceMatchForm.deviceName = device.deviceName;
                        deviceMatchForm.deviceMac = device.deviceMac;
                        if (device.callNumbers.length > 0) {
                          const callNumbers = device.callNumbers;
                          callNumbers.forEach((item) => {
                            const name = item.name;
                            const number = item.number;
                            if (item.index === 0) {
                              deviceMatchForm.elevator1 = name;
                              deviceMatchForm.callNumber1 = number;
                            } else if (item.index === 1) {
                              deviceMatchForm.elevator2 = name;
                              deviceMatchForm.callNumber2 = number;
                            } else if (item.index === 2) {
                              deviceMatchForm.elevator3 = name;
                              deviceMatchForm.callNumber3 = number;
                            } else if (item.index === 3) {
                              deviceMatchForm.elevator4 = name;
                              deviceMatchForm.callNumber4 = number;
                            }
                          });
                        }
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            };

            const doDeviceMatch = () => {
              const matchParam = {};
              let haveUpdateItems = false;
              if (anziotUtils.isNotNull(deviceMatchForm.elevator1)) {
                matchParam.elevator1 = deviceMatchForm.elevator1;
                matchParam.callNumber1 = deviceMatchForm.callNumber1;
                haveUpdateItems = true;
              }
              if (anziotUtils.isNotNull(deviceMatchForm.elevator2)) {
                matchParam.elevator2 = deviceMatchForm.elevator2;
                matchParam.callNumber2 = deviceMatchForm.callNumber2;
                haveUpdateItems = true;
              }
              if (anziotUtils.isNotNull(deviceMatchForm.elevator3)) {
                matchParam.elevator3 = deviceMatchForm.elevator3;
                matchParam.callNumber3 = deviceMatchForm.callNumber3;
                haveUpdateItems = true;
              }
              if (anziotUtils.isNotNull(deviceMatchForm.elevator4)) {
                matchParam.elevator4 = deviceMatchForm.elevator4;
                matchParam.callNumber4 = deviceMatchForm.callNumber4;
                haveUpdateItems = true;
              }
              if (haveUpdateItems) {
                request.matchDevice(props.deviceKey, matchParam).then(res => {
                    if (res.success) {
                        ElMessage.success(res.message);
                        ctx.emit('update:visible', false);
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
              } else {
                ElMessage.error('未填写对码信息，无法保存');
              }
              // console.log('DialogDeviceMatch-doDeviceMatch', props.deviceKey, deviceMatchForm);
            }

            return {
              deviceMatchFormRef,
              deviceMatchForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              doDeviceMatch
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
.descriptions-item-label {
    width: 150px;
}
</style>
