<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="searchForm.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="所属代理商名称" prop="agentName">
          <el-input v-model="searchForm.agentName" placeholder="请输入所属代理商名称"></el-input>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleProjectCreate">新建项目</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="projectName" label="项目名称" />
      <el-table-column prop="agentName" label="所属代理商" />
      <el-table-column prop="deviceHostCount" label="主机数量" />
      <el-table-column prop="deviceExtCount" label="分机数量"/>
      <el-table-column prop="callNumberCount" label="总局数" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column fixed="right" label="操作" width="180">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleProjectDetail(scope.row.projectKey)">详情</el-button>
          <el-button link type="primary" size="small" @click="handleProjectEdit(scope.row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[20, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogProjectDetail
      v-model:visible="projectDetailProps.visible"
      v-model:projectKey="projectDetailProps.projectKey"
    />
    <DialogProjectEdit
      v-model:visible="projectEditProps.visible"
      v-model:project="projectEditProps.project"
    />
    <DialogProjectCreate
      v-model:visible="projectCreateProps.visible"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import DialogProjectDetail from '@/components/dialog/DialogProjectDetail.vue';
  import DialogProjectEdit from '@/components/dialog/DialogProjectEdit.vue';
  import DialogProjectCreate from '@/components/dialog/DialogProjectCreate.vue';

  const tableData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(20);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryProjectList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryProjectList();
  }

  const queryProjectList = () => {
    const queryParam = {};
    if (anziotUtils.isNotNull(searchForm.projectName)) {
      queryParam.projectName = searchForm.projectName;
    }
    if (anziotUtils.isNotNull(searchForm.agentName)) {
      queryParam.agentName = searchForm.agentName;
    }
    request.queryProjects(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            tableData.value.push({
              projectKey: item.projectKey,
              projectName: item.projectName,
              agentKey: item.agentKey,
              agentName: item.agentName,
              deviceHostCount: item.hostDeviceCount,
              deviceExtCount: item.extentionCount,
              callNumberCount: item.callNumberCount,
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取项目数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  }

  const searchFormRef = ref(null);
  const searchForm = reactive({
    projectName: '',
    agentName: ''
  });
  const onReset = () => {
    searchFormRef.value.resetFields();
    queryProjectList();
  };

  const onSearch = () => {
    queryProjectList();
  };

  onActivated(
    () => {
      queryProjectList();
    }
  )

  /* 对话框 */
  const projectDetailProps = ref({ visible: false, projectKey: undefined })
  const projectEditProps = ref({ visible: false, project: undefined })
  const projectCreateProps = ref({ visible: false })

  const handleProjectDetail = (projectKey) => {
    projectDetailProps.value.visible = true
    projectDetailProps.value.projectKey = projectKey
    // console.log('handleProjectDetail', projectKey, projectDetailProps.value)
  }

  const handleProjectEdit = (project) => {
    projectEditProps.value.visible = true
    projectEditProps.value.project = project
    // console.log('handleProjectEdit', project, projectEditProps.value)
  }

  const handleProjectCreate = () => {
    projectCreateProps.value.visible = true
    // console.log('handleProjectCreate', projectCreateProps.value)
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
</style>
