<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="新建虚拟主机"
        width="600px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-row>
            <el-col :span="24">
              <el-text type="danger" style="margin-left: 160px;">APP登录账号: {{ virtualDeviceCreateForm.deviceMac }}</el-text>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-text type="danger" style="margin-left: 160px;">默认密码: 123456</el-text>
            </el-col>
          </el-row>
          <el-form label-width="200px" :model="virtualDeviceCreateForm" style="margin-top: 30px;">
            <el-form-item prop="deviceMac" label="设备编号（必填）">
              <el-input type="text" v-model="virtualDeviceCreateForm.deviceMac"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doVirtualDeviceCreate" style="margin-top: 30px;">创建</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogVirtualDeviceCreate',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });
            const virtualDeviceCreateForm = reactive({
                deviceMac: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              virtualDeviceCreateForm.deviceMac = undefined;
            };

            const doVirtualDeviceCreate = () => {
              if (!virtualDeviceCreateForm.deviceMac || virtualDeviceCreateForm.deviceMac.trim().length < 1) {
                ElMessage.error('请输入虚拟主机编码');
                return
              }
              const createParam = {
                deviceMac: virtualDeviceCreateForm.deviceMac
              };
              request.createVirtualDevice(createParam).then(res => {
                if (res.success) {
                  ElMessage.success('虚拟主机创建成功');
                  ctx.emit('update:visible', false);
                  props.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogVirtualDeviceCreate-doVirtualDeviceCreate', props, virtualDeviceCreateForm);
            }

            return {
              virtualDeviceCreateForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              doVirtualDeviceCreate
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
