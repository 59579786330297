<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>通话记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="设备编号" prop="deviceMac">
          <el-input v-model="searchForm.deviceMac" placeholder="请输入设备编号"></el-input>
        </el-form-item>
        <el-form-item label="通话方名称" prop="deviceName">
          <el-input v-model="searchForm.deviceName" placeholder="请输入通话方名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="searchForm.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item> -->
        <el-form-item label="呼叫号码" prop="callNumber">
          <el-input v-model="searchForm.callNumber" placeholder="请输入呼叫号码"></el-input>
        </el-form-item>
        <el-form-item label="通话状态" prop="callState">
          <el-select v-model="searchForm.callState" clearable placeholder="请选择通话状态">
            <el-option
              v-for="item in [
                {value: 0, label: '未知'},
                {value: 1, label: '通话中断'},
                {value: 2, label: '拒绝接听'},
                {value: 3, label: '呼叫未接听'},
                {value: 4, label: '主叫方挂断'},
                {value: 5, label: '被叫方挂断'},
                {value: 6, label: '通话超时'},
                {value: 7, label: '被叫方未注册'},
                {value: 8, label: '被叫方离线'},
                {value: 9, label: '主叫方忙线'},
                {value: 10, label: '被叫方忙线'},
                {value: 11, label: '通话错误'},
                {value: 12, label: '线路异常（弱网）'},
                {value: 13, label: '通话取消'},
                {value: 14, label: '通话已接听'},
                {value: 15, label: '主叫方限制呼叫'}
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="callDeviceName" label="主叫名称" />
      <el-table-column prop="callDeviceCallNumber" label="主叫号码" align="center" width="160"/>
      <el-table-column prop="calledDeviceName" label="被叫名称" />
      <el-table-column prop="calledDeviceCallNumber" label="被叫号码" align="center" width="160"/>
      <el-table-column prop="callStateName" label="通话状态" align="center" width="165">
        <template #default="scope">
          <el-tooltip :content="scope.row.hangupReason" placement="top-start">
            <el-text>{{ scope.row.callStateName }}</el-text>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="inviteTime" label="呼叫时间" align="center" width="165"/>
      <el-table-column prop="hangupTime" label="挂断时间" align="center" width="165"/>
      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleCallRecordDetail(scope.row.callRecordKey)">通话详情</el-button>
          <el-popconfirm
            width="350"
            :title="'确定要删除此通话记录（不可恢复）?'"
            @confirm="handleCallRecordRemove(scope.row.callRecordKey)"
            confirm-button-text="删除"
            cancel-button-text="取消"
          >
            <template #reference>
              <el-button link type="primary" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[20, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogCallRecordDetail
      v-model:visible="callRecordDetailProps.visible"
      v-model:callRecordKey="callRecordDetailProps.callRecordKey"
      v-model:reload="queryCallRecordList"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import DialogCallRecordDetail from '@/components/dialog/DialogCallRecordDetail.vue';

  const tableData = ref([]);

  const currentPage = ref(1);
  const pageSize = ref(20);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryCallRecordList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryCallRecordList();
  }

  const queryCallRecordList = () => {
    const queryParam = {};
    if (anziotUtils.isNotNull(searchForm.deviceMac)) {
      queryParam.deviceMac = searchForm.deviceMac;
    }
    if (anziotUtils.isNotNull(searchForm.deviceName)) {
      queryParam.deviceName = searchForm.deviceName;
    }
    if (anziotUtils.isNotNull(searchForm.projectName)) {
      queryParam.projectName = searchForm.projectName;
    }
    if (anziotUtils.isNotNull(searchForm.callNumber)) {
      queryParam.callNumber = searchForm.callNumber;
    }
    if (anziotUtils.isNotNull(searchForm.callState)) {
      queryParam.callState = searchForm.callState;
    }
    request.queryCallRecords(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            const calledName = item.calledDeviceName === null ? item.calledDeviceCallNumber : item.calledDeviceName;
            const calledMac = item.calledDeviceMac === null ? '' : ' (' + item.calledDeviceMac + ')';
            tableData.value.push({
              callRecordKey: item.callRecordKey,
              callDeviceName: item.callDeviceName + ' (' + item.callDeviceMac + ')',
              callDeviceCallNumber: item.callDeviceCallNumber,
              calledDeviceName: calledName + calledMac,
              calledDeviceCallNumber: item.calledDeviceCallNumber,
              callStateName: item.callStatusName,
              hangupReason: item.hangupReason,
              inviteTime: anziotUtils.getTimestampStr(item.inviteTime),
              hangupTime: anziotUtils.getTimestampStr(item.hangupTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取通话记录数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  }

  const searchFormRef = ref(null);
  const searchForm = reactive({
    projectName: '',
    deviceMac: '',
    deviceName: '',
    callNumber: '',
    callState: undefined
  });

  const onReset = () => {
    searchFormRef.value.resetFields();
    queryCallRecordList();
  };

  const onSearch = () => {
    queryCallRecordList();
  };

  onActivated(
    () => {
      queryCallRecordList();
    }
  )

  const handleCallRecordRemove = (callRecordKey) => {
    request.removeCallRecords(callRecordKey).then(res => {
       if (res.success) {
        ElMessage.success('删除通话记录成功');
       } else {
        ElMessage.error(res.message);
       }
    }).catch(error => {
        console.log(error)
    });
  };

  /* 对话框 */
  const callRecordDetailProps = ref({ visible: false, callRecordKey: undefined, reload: () => {} })

  const handleCallRecordDetail = (callRecordKey) => {
    callRecordDetailProps.value.visible = true
    callRecordDetailProps.value.callRecordKey = callRecordKey
    callRecordDetailProps.value.reload = queryCallRecordList
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
</style>
