<template>
    <div class="icon-network">
        <el-tooltip
            class="box-item"
            effect="light"
            :content="tips"
            placement="right"
        >
            <svg class="icon" aria-hidden="true" :style="{color: iconColor}">
              <use :xlink:href="icon"></use>
            </svg>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'IconNetwork',
        props: {
            networkQuality: {
                type: Number,
                default: -1
            }
        },
        data() {
            let iconName = '#el-icon-unknown'
            let iconColor = 'green'
            let tipsContent = '在线'
            if (this.networkQuality === 0) {
                iconName = '#el-icon-weilianjie'
                iconColor = 'red'
                tipsContent = '离线'
            } else if (this.networkQuality === 1) {
                iconColor = 'orange'
                iconName = '#el-icon-wuxian-3'
            } else if (this.networkQuality === 2) {
                iconColor = 'orange'
                iconName = '#el-icon-wuxian-3'
            } else if (this.networkQuality === 3) {
                iconColor = 'orange'
                iconName = '#el-icon-wuxian-1'
            } else if (this.networkQuality === 4) {
                iconName = '#el-icon-wuxian-2'
            } else if (this.networkQuality === 5) {
                iconName = '#el-icon-wuxian-4'
            }
            return {
                tips: tipsContent,
                iconColor: iconColor,
                icon: iconName,
                version: 0
            };
        }
        // watch: {
        //     networkQuality(newValue, oldValue) {
        //         console.log('value changed from', oldValue, 'to', newValue)
        //         const networkQuality = newValue;
        //         let iconName = '#el-icon-unknown'
        //         let iconColor = 'green'
        //         let tipsContent = '在线'
        //         if (networkQuality === 0) {
        //             iconName = '#el-icon-weilianjie'
        //             iconColor = 'red'
        //             tipsContent = '离线'
        //         } else if (networkQuality === 1) {
        //             iconName = '#el-icon-wuxian-3'
        //         } else if (networkQuality === 2) {
        //             iconName = '#el-icon-wuxian-3'
        //         } else if (networkQuality === 3) {
        //             iconName = '#el-icon-wuxian-1'
        //         } else if (networkQuality === 4) {
        //             iconName = '#el-icon-wuxian-2'
        //         } else if (networkQuality === 5) {
        //             iconName = '#el-icon-wuxian-4'
        //         }
        //         const conf = {
        //             tips: tipsContent,
        //             iconColor: iconColor,
        //             icon: iconName,
        //             version: new Date().getTime()
        //         };
        //         console.log(conf);
        //         return conf;
        //     }
        // }
    };
</script>

<style scoped>

</style>
