<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="代理商设置"
        width="550px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-form label-width="100px" :model="agentEditForm" ref="agentEditFormRef">
            <el-form-item prop="agentName" label="代理商名称">
              <el-input type="text" v-model="agentEditForm.agentName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doAgentEdit">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogAgentEdit',
        props: {
            agent: {
                type: Object,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const agentEditForm = reactive({
                agentName: ''
            });

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              agentEditForm.agentName = props.agent.agentName;
              agentEditForm.agentKey = props.agent.agentKey;
              // console.log('DialogAgentEdit-openDialog', props.agent, agentEditForm);
            };

            const doAgentEdit = () => {
              // console.log('DialogAgentEdit-doAgentEdit', props.agent, agentEditForm);
              const updateParam = {
                agentName: agentEditForm.agentName
              };
              request.updateAgent(agentEditForm.agentKey, updateParam).then(res => {
                if (res.success) {
                  ElMessage.success('更新成功');
                  ctx.emit('update:visible', false);
                  parent.location.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogAgentEdit-doAgentEdit', props, agentEditForm);
            }

            return {
              agentEditForm,
              agentList,
              isDialogVisible,
              loadingAgentListQuery,
              closeDialog,
              openDialog,
              doAgentEdit
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
