<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="修改密码"
        width="550px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-form label-width="100px" :model="resetPwdForm" ref="resetPwdFormRef">
            <el-form-item prop="account" label="当前账号">
              <el-text>{{ currentAccount }}</el-text>
            </el-form-item>
            <el-form-item prop="oldPassword" label="旧密码">
              <el-input type="password" v-model="resetPwdForm.oldPwd"></el-input>
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码">
              <el-input type="password" v-model="resetPwdForm.newPwd"></el-input>
            </el-form-item>
            <el-form-item prop="newPasswordConfirm" label="确认新密码">
              <el-input type="password" v-model="resetPwdForm.newPwdConfirm"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doResetPassword">修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import JSEncrypt from 'jsencrypt';
    import request from '../../utils/interface.js';
    import store from '../../store/index.js';
    import anziotUtils from '@/utils/anziotUtils';

    export default {
        name: 'DialogSystemResetPwd',
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const currentAccount = store.getters.getAccount;

            const resetPwdForm = reactive({
              oldPwd: '',
              newPwd: '',
              newPwdConfirm: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              // console.log('DialogSystemResetPwd-openDialog', props.project, resetPwdForm);
            };

            const doResetPassword = () => {
              if (resetPwdForm.newPwd !== resetPwdForm.newPwdConfirm) {
                ElMessage.error('两次输入的新密码不一致');
                return;
              }
              const pk = store.state.system_pk;
              if (anziotUtils.isNotNull(pk) && pk.length > 0) {
                const text = '{"oldPassword": "' + resetPwdForm.oldPwd + '","newPassword": "' + resetPwdForm.newPwd + '"}';
                const jsrsa = new JSEncrypt();
                jsrsa.setPublicKey(pk);
                const rsatext = jsrsa.encrypt(text);
                const rtext = rsatext.replaceAll('+', ' ')
                const rsaEnText = encodeURIComponent(rtext);
                request.resetPassword(rsaEnText).then(res => {
                  if (res.success) {
                    ElMessage.success('密码更新成功');
                    ctx.emit('update:visible', false);
                    parent.location.reload();
                  } else {
                    ElMessage.error(res.message);
                  }
                }).catch(error => {
                    console.log(error)
                });
              } else {
                ElMessage.error('用户信息缺失，请重新登录后再次尝试本操作');
              }
              // console.log('DialogSystemResetPwd-doResetPassword', props, resetPwdForm);
            }

            return {
              currentAccount,
              resetPwdForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              doResetPassword
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
