<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="删除代理商"
        width="500px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px; width:100%">
          <div class="centered">
            <el-text tag="b" type="danger" size="large">确认删除当前代理商：{{ parentAgentName }}？</el-text>
          </div>
          <div  class="centered" style="margin-top: 15px">
            <el-text>提示：删除代理商后，其关联的设备将归属 【{{ belongAgentName }}】</el-text>
          </div>
          <div  class="centered" style="margin-top: 30px">
            <el-button type="danger" @click="doAgentDelete">确认删除</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogAgentDelete',
        props: {
            agentKey: {
                type: String,
                default: undefined
            },
            agentName: {
                type: String,
                default: undefined
            },
            targetAgentName: {
                type: String,
                default: undefined
            },
            targetAgentKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const parentAgentKey = computed({
                get() {
                    return props.agentKey
                },
                set(val) {
                    return val
                }
            });
            const parentAgentName = computed({
                get() {
                    return props.agentName
                },
                set(val) {
                    return val
                }
            });
            const belongAgentName = computed({
                get() {
                    return props.targetAgentName
                },
                set(val) {
                    return val
                }
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              // console.log('DialogAgentDelete-openDialog', props);
            };

            const doAgentDelete = () => {
              request.deleteAgent(parentAgentKey.value).then(res => {
                if (res.success) {
                  ElMessage.success('代理商删除成功');
                  ctx.emit('update:visible', false);
                  props.reload(props.targetAgentKey);
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogAgentDelete-doAgentDelete', props);
            }

            return {
              parentAgentKey,
              parentAgentName,
              belongAgentName,
              isDialogVisible,
              closeDialog,
              openDialog,
              doAgentDelete
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
