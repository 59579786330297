<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="项目设置"
        width="550px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-form label-width="100px" :model="projectEditForm" ref="projectEditFormRef">
            <el-form-item prop="projectName" label="项目名称">
              <el-input type="text" v-model="projectEditForm.projectName"></el-input>
            </el-form-item>
            <el-form-item prop="agent" label="所属代理商">
              <el-select
                  placeholder="请选择从属代理商"
                  clearable
                  filterable
                  remote
                  :remote-method="loadAgentList"
                  :loading="loadingAgentListQuery"
                  v-model="projectEditForm.agentName"
                  @change="refreshData"
                  @focus="loadAgentList"
              >
                <el-option
                  v-for="item in agentList"
                  :key="item.agentKey"
                  :label="item.agentName"
                  :value="item.agentKey"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doProjectEdit">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '../../utils/interface.js';

    export default {
        name: 'DialogProjectEdit',
        props: {
            project: {
                type: Object,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const projectEditForm = reactive({
                projectName: '',
                agentKey: '',
                agentName: ''
            });

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const closeDialog = () => {
                ctx.emit('update:visible', false);
            };

            const openDialog = () => {
              projectEditForm.projectName = props.project.projectName;
              projectEditForm.agentKey = props.project.agentKey;
              projectEditForm.agentName = props.project.agentName;
              // console.log('DialogProjectEdit-openDialog', props.project, projectEditForm);
            };

            const doProjectEdit = () => {
              const updateParam = {
                projectName: projectEditForm.projectName,
                agentKey: projectEditForm.agentKey
              };
              request.updateProject(props.project.projectKey, updateParam).then(res => {
                if (res.success) {
                  ElMessage.success('项目更新成功');
                  ctx.emit('update:visible', false);
                  parent.location.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogProjectEdit-doProjectEdit', props, projectEditForm);
            }

            const refreshData = (val) => {
              projectEditForm.agentKey = val;
            }

            const loadAgentList = (param) => {
              const queryParam = { agentName: '', isOnlyAgent: true };
              if (typeof(param) === 'string') {
                queryParam.agentName = param;
              }
              loadingAgentListQuery.value = true
                setTimeout(() => {
                  request.queryAgents(1, 10, queryParam).then(res => {
                    if (res.success) {
                      agentList.value = [];
                        res.data.rows.forEach((item) => {
                          agentList.value.push({
                            agentKey: item.agentKey,
                            agentName: item.agentName
                          });
                        });
                    } else {
                      ElMessage.error('获取代理商数据失败');
                    }
                  }).catch(error => {
                      console.log(error)
                  });
                  loadingAgentListQuery.value = false
                }, 300)
            }

            return {
              projectEditForm,
              agentList,
              isDialogVisible,
              loadingAgentListQuery,
              closeDialog,
              openDialog,
              doProjectEdit,
              refreshData,
              loadAgentList
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
