<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>代理商管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <el-form-item label="代理商名称" prop="agentName">
          <el-input v-model="searchForm.agentName" placeholder="请输入代理商名称"></el-input>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleAgentCreate">新建代理商</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%; margin-top: 10px;"
      >
      <el-table-column prop="agentName" label="代理商名称" width="320"/>
      <el-table-column prop="projectCount" label="项目数量" width="80"/>
      <el-table-column prop="deviceHostCount" label="主机数量" width="80"/>
      <el-table-column prop="deviceExtCount" label="分机数量" width="80"/>
      <el-table-column prop="callNumberCount" label="总局数" width="80"/>
      <el-table-column prop="createTime" label="创建时间" width="180"/>
      <el-table-column prop="agentTree" label="从属关系" :show-overflow-tooltip="true"/>
      <el-table-column fixed="right" label="操作" width="180">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleAgentEdit(scope.row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[20, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogAgentEdit
      v-model:visible="agentEditProps.visible"
      v-model:agent="agentEditProps.agent"
    />
    <DialogAgentCreate
      v-model:visible="agentCreateProps.visible"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import DialogAgentEdit from '@/components/dialog/DialogAgentEdit.vue';
  import DialogAgentCreate from '@/components/dialog/DialogAgentCreate.vue';

  const tableData = ref([]);

  const currentPage = ref(1);
  const pageSize = ref(20);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryAgentList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryAgentList();
  }

  const queryAgentList = () => {
    const queryParam = {};
    if (anziotUtils.isNotNull(searchForm.agentName)) {
      queryParam.agentName = searchForm.agentName;
    }
    request.queryAgents(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            let agentTree = '';
            item.agentPath.forEach((item) => {
              agentTree = agentTree + ' / ' + item.name;
            });
            tableData.value.push({
              agentKey: item.agentKey,
              agentName: item.agentName,
              agentTree: agentTree,
              projectCount: item.projectCount,
              deviceHostCount: item.hostDeviceCount,
              deviceExtCount: item.extentionCount,
              callNumberCount: item.callNumberCount,
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取代理商数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  }

  const searchFormRef = ref(null);
  const searchForm = reactive({
    agentName: ''
  });
  const onReset = () => {
    searchFormRef.value.resetFields();
    queryAgentList();
  };

  const onSearch = () => {
    queryAgentList();
  };

  onActivated(
    () => {
      queryAgentList();
    }
  )

  /* 对话框 */
  const agentEditProps = ref({ visible: false, agent: undefined })
  const agentCreateProps = ref({ visible: false })

  const handleAgentEdit = (agent) => {
    agentEditProps.value.visible = true
    agentEditProps.value.agent = agent
    // console.log('handleAgentEdit', agent, agentEditProps.value)
  }

  const handleAgentCreate = () => {
    agentCreateProps.value.visible = true
    // console.log('handleAgentCreate', agentEditProps.value)
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }
</style>
