<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>代理商主/分机添加</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="selection">
      <el-row style="margin-top: 10px;">
        <el-col :span="20">
          <el-text size="large" style="margin-left: 10px;">已选择设备 {{ selectDevices.length }} 台（绿色为主机、蓝色为分机）</el-text>
        </el-col>
        <el-col :offset="1" :span="2">
          <el-button type="primary" @click="onSelectionDone">添加至代理商</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 15px">
        <el-col :span="24">
          <el-tag
            style="margin-left: 10px; margin-bottom: 10px"
            v-for="device in selectDevices"
            :key="device.deviceKey"
            closable
            :type="device.deviceType === 0 ? '' : 'success'"
            @close="onSelectDeviceCancel(device)"
          >
          {{ device.deviceMac ? device.deviceMac : '未知设备编号(' + device.imei.substring((device.imei.length - 4), device.imei.length) + ')' }}
          </el-tag>
        </el-col>
      </el-row>
    </div>
    <div class="search">
      <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
        <!-- <el-form-item label="设备IMEI" prop="deviceImei">
          <el-input v-model="searchForm.deviceImei" placeholder="请扫描模块上二维码或输入设备IMEI" style="width: 260px" @input="onDeviceIMEIInput" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="设备SIM" prop="simIccid">
          <el-input v-model="searchForm.simIccid" placeholder="请输入设备SIM卡ICCID码" style="width: 260px" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceMac">
          <el-input v-model="searchForm.deviceMac" placeholder="请输入设备编号" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="在线状态" prop="onlineState">
          <el-select v-model="searchForm.onlineState" class="m-2" placeholder="请选择设备在线状态" clearable>
            <el-option
              v-for="item in [{value: 0, label: '离线'},{value: 1, label: '在线'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" style="width: 260px" clearable></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="searchForm.deviceType" class="m-2" placeholder="请选择设备类型" style="width: 260px" clearable>
            <el-option
              v-for="item in [{value: 1, label: '主机'},{value: 0, label: '分机'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="所属项目名称" prop="projectName">
          <el-input v-model="searchForm.projectName" placeholder="请输入所属项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item class="search_btns">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="info" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTableRef"
      :data="tableData"
      stripe
      highlight-current-row
      :v-loading = "tableData.length > 0 ? false : true"
      :row-key="(row) => row.deviceKey"
      :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%; margin-top: 10px;"
      @select="onDeviceSelect"
      @select-all="onDeviceSelectAll"
      >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="deviceName" label="设备名称" width="160" show-overflow-tooltip/>
      <el-table-column prop="deviceMac" label="设备编号" width="180">
        <template #default="scope">
          {{ null === scope.row.deviceMac ? '—' : scope.row.deviceMac }}
        </template>
      </el-table-column>
      <el-table-column prop="deviceTypeName" label="设备类型" width="100"/>
      <el-table-column prop="agentName" label="所属代理商" width="180" show-overflow-tooltip/>
      <el-table-column prop="projectName" label="所属项目" width="180" show-overflow-tooltip/>
      <el-table-column prop="masterDeviceName" label="所属设备" width="160" show-overflow-tooltip/>
      <el-table-column prop="networkQuality" label="在线状态"  width="80">
        <template #default="scope">
            <IconNetwork :networkQuality="scope.row.networkQuality" :key="new Date().getTime()"/>
        </template>
      </el-table-column>
      <el-table-column prop="imei" label="设备IMEI" width="150"/>
      <el-table-column prop="simIccid" label="SIM-ICCID" width="200"/>
      <el-table-column prop="callNumberCount" label="局数" width="80"/>
      <el-table-column prop="onlineTime" label="最近上线时间" width="165"/>
      <el-table-column fixed="right" label="操作" width="100">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleDeviceDetail(scope.row.deviceKey)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[15, 50, 100, 200]"
      :small=false
      :disabled=false
      :background=true
      layout="->, total, prev, pager, next, sizes"
      :total="total"
      style="margin-top: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <DialogDeviceDetail
      v-model:visible="deviceDetailProps.visible"
      v-model:deviceKey="deviceDetailProps.deviceKey"
      v-model:reload="queryDeviceList"
    />
    <el-dialog
      v-model="isTransferDialogVisible"
      title="添加至代理商"
      width="600px"
      @close='closeDialog'
      @open='openDialog'
      >
      <div style="margin-top: 10px;">
        <el-form label-width="150px" :model="deviceTransferInfoForm" ref="deviceTransferInfoFormRef">
          <el-form-item prop="agentName" label="代理商">
            <el-select
              v-model="deviceTransferInfoForm.agentName"
              value-key="agentKey"
              placeholder="请选择代理商"
              clearable
              filterable
              remote
              :remote-method="loadAgentList"
              :loading="loadingAgentListQuery"
              @change="refreshAgentData"
              @focus="loadAgentList"
            >
              <el-option
                v-for="item in agentList"
                :key="item.agentKey"
                :label="item.agentName"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="masterDeviceMac" label="归属主设备编号">
            <el-input v-model="deviceTransferInfoForm.targetDeivceMac" :disabled="!deviceTransferInfoForm.agentKey || deviceTransferInfoForm.agentKey.length < 10" @input="onMasterDeviceMacInput" />
          </el-form-item>
          <el-descriptions
              v-loading="loading"
              title="划拨信息确认"
              direction="horizontal"
              :column="1"
              border
              style="margin-top: 20px"
              >
              <el-descriptions-item label="划拨至代理商" label-class-name="descriptions-item-label">
                {{ deviceTransferInfoForm.agentName }}
              </el-descriptions-item>
              <el-descriptions-item label="归属主设备编号" label-class-name="descriptions-item-label" width="240">
                {{ deviceTransferInfoForm.masterDeviceMac }}
              </el-descriptions-item>
              <el-descriptions-item label="本次划拨设备数量" label-class-name="descriptions-item-label">
                {{ selectDevices.length }}台
              </el-descriptions-item>
            </el-descriptions>
          <el-form-item>
            <el-button type="primary" @click="confirmTransfer" style="margin-top: 20px">确认划拨</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import IconNetwork from '@/components/icon/IconNetwork.vue';
  import DialogDeviceDetail from '@/components/dialog/DialogDeviceDetail.vue';

  // dialog
  const isTransferDialogVisible = ref(false)
  const deviceTransferInfoFormRef = ref()
  const deviceTransferInfoForm = reactive({
            agentKey: '',
            agentName: '',
            projectKey: '',
            projectName: ''
        });

  const closeDialog = () => {
    // console.log('closeDialog')
    deviceTransferInfoFormRef.value.resetFields();
    queryDeviceList();
  }

  const openDialog = () => {
    // console.log('openDialog')
    deviceTransferInfoForm.masterDeviceMac = ''
    deviceTransferInfoFormRef.value.resetFields();
  }

  // 代理商
  const agentList = ref([])
  const loadingAgentListQuery = ref(false)

  const refreshAgentData = (val) => {
    deviceTransferInfoForm.agentKey = val.agentKey;
    deviceTransferInfoForm.agentName = val.agentName;
    deviceTransferInfoForm.projectKey = '';
    deviceTransferInfoForm.projectName = '';
  }

  const loadAgentList = (param) => {
    agentList.value = [];
    const queryParam = { agentName: '' };
    if (typeof(param) === 'string') {
      queryParam.agentName = param;
    }
    loadingAgentListQuery.value = true
      setTimeout(() => {
        request.queryAgents(1, 10000, queryParam).then(res => {
          if (res.success) {
              res.data.rows.forEach((item) => {
                agentList.value.push({
                  agentKey: item.agentKey,
                  agentName: item.agentName
                });
              });
          } else {
            ElMessage.error('获取代理商数据失败');
          }
        }).catch(error => {
            console.log(error)
        });
        loadingAgentListQuery.value = false
      }, 300)
  }

  // 项目
  // const projectList = ref([])
  // const loadingProjectListQuery = ref(false)

  // const refreshProjectData = (val) => {
  //   deviceTransferInfoForm.projectKey = val.projectKey;
  //   deviceTransferInfoForm.projectName = val.projectName;
  // }

  // const loadProjectList = (param) => {
  //   projectList.value = [];
  //   if (!deviceTransferInfoForm.agentKey || deviceTransferInfoForm.agentKey.length < 2) {
  //     ElMessage.error('请先选择代理商');
  //     return
  //   }
  //   const queryParam = {
  //     projectName: '',
  //     agentKey: deviceTransferInfoForm.agentKey
  //   };
  //   if (typeof(param) === 'string') {
  //     queryParam.projectName = param;
  //   }
  //   loadingProjectListQuery.value = true
  //     setTimeout(() => {
  //       request.queryProjects(1, 10000, queryParam).then(res => {
  //         if (res.success) {
  //             res.data.rows.forEach((item) => {
  //               projectList.value.push({
  //                 projectKey: item.projectKey,
  //                 projectName: item.projectName
  //               });
  //             });
  //         } else {
  //           ElMessage.error('获取项目数据失败');
  //         }
  //       }).catch(error => {
  //           console.log(error)
  //       });
  //       loadingProjectListQuery.value = false
  //     }, 300)
  // }
  const loading = ref(false)
  const onMasterDeviceMacInput =() => {
    const targetDeviceMac = deviceTransferInfoForm.targetDeivceMac
    if (deviceTransferInfoForm.agentKey === undefined || deviceTransferInfoForm.agentKey.length < 2) {
      ElMessage.error('请选择代理商');
      return
    }
    if (targetDeviceMac === undefined ||
          (targetDeviceMac.length < 12 &&
          targetDeviceMac.length > 0)) {
            deviceTransferInfoForm.masterDeviceMac = '无'
            deviceTransferInfoForm.masterDeviceKey = ''
            return
    }
    loading.value = true
    setTimeout(() => {
      const queryParam = {
        agentKey: deviceTransferInfoForm.agentKey,
        deviceMac: deviceTransferInfoForm.targetDeivceMac,
        c: [1, 2]
      };
      deviceTransferInfoForm.masterDeviceMac = '无'
      deviceTransferInfoForm.masterDeviceKey = ''
      request.queryDevices(1, 1, queryParam).then(res => {
        if (res.success) {
          const dev = res.data.rows[0];
          if (dev) {
            deviceTransferInfoForm.masterDeviceKey = dev.deviceKey
            deviceTransferInfoForm.masterDeviceMac = dev.deviceMac
          } else {
            ElMessage.error('未找到主设备');
          }
        }
      }).catch(error => {
          console.log(error)
      });
      loading.value = false
    }, 300);
    // console.log('onMasterDeviceMacInput', deviceTransferInfoForm)
  }

  const confirmTransfer = () => {
    // console.log('confirmTransfer', deviceTransferInfoForm)
    if (!deviceTransferInfoForm.agentKey || deviceTransferInfoForm.agentKey.length < 2) {
      ElMessage.error('请选择代理商');
    } else if (selectDevices.value.length === 0) {
      ElMessage.error('请选择需要操作的设备');
    } else {
      const deviceKeys = []
      selectDevices.value.forEach((item) => {
        deviceKeys.push(item.deviceKey)
      })
      const transferParam = {
        agentKey: deviceTransferInfoForm.agentKey,
        masterDeviceKey: deviceTransferInfoForm.masterDeviceKey,
        deviceKeys: deviceKeys
      }
      request.batchTransferDevice(transferParam).then(res => {
        if (res.success) {
          ElMessage.success('设备操作成功');
          setTimeout(() => {
            selectDevices.value.length = 0
            selectDeviceMap.clear()
            isTransferDialogVisible.value = false
            multipleTableRef.value.clearSelection()
            queryDeviceList()
          }, 0);
        } else {
          ElMessage.error(res.message);
        }
      }).catch(error => {
          console.log(error)
      });
      // console.log('confirmTransfer-do');
    }
  };

  const onSelectionDone = () => {
    if (selectDevices.value.length === 0) {
      ElMessage.error('请选择需要操作的设备');
    } else {
      deviceTransferInfoForm.agentKey = ''
      deviceTransferInfoForm.agentName = ''
      deviceTransferInfoForm.masterDeviceMac = '无'
      deviceTransferInfoForm.masterDeviceKey = ''
      deviceTransferInfoForm.targetDeivceMac = ''
      isTransferDialogVisible.value = true
    }
  }

  // tags
  const selectDevices = ref([])
  const selectDeviceMap = new Map()

  const onSelectDeviceCancel = (item) => {
    const index = selectDevices.value.indexOf(item)
    const removeDevice = selectDevices.value[index]
    // console.log(removeDevice, item, index)
    selectDeviceMap.delete(removeDevice.deviceKey)
    selectDevices.value.splice(index, 1)
    innerRefeshToggleRow()
  }

  const onDeviceSelect = (selections, rows) => {
    // console.log('onDeviceSelect', selections, rows)
    innerAddSelectDevice(selections)
    innerRefeshToggleRow()
  }

  const onDeviceSelectAll = (selections) => {
    // console.log('onDeviceSelectAll', selections);
    innerAddSelectDevice(selections)
  }

  const innerAddSelectDevice = (selections) => {
    // console.log('innerAddSelectDevice', selectDeviceMap)
    if (tableData.value.length > 0) {
      tableData.value.forEach((row) => {
        selectDeviceMap.delete(row.deviceKey)
      })
    }
    if (selections && selections.length > 0) {
      selections.forEach((row) => {
        selectDeviceMap.set(row.deviceKey, row)
      })
    }
    const values = []
    selectDeviceMap.forEach((value, key) => {
      values.push(value)
    })
    selectDevices.value = values
  }

  const multipleTableRef = ref([]);
  const innerRefeshToggleRow = () => {
    multipleTableRef.value.clearSelection()
    if (selectDevices.value.length > 0) {
      for (let i = 0; i < tableData.value.length; i++) {
        const item = tableData.value[i]
        selectDevices.value.forEach((row) => {
          if (item.deviceKey === row.deviceKey) {
            setTimeout(() => {
              // console.log('innerRefeshToggleRow', item)
              multipleTableRef.value.toggleRowSelection(item, true)
            }, 0);
          }
        })
      }
    }
  }

  // table
  const tableData = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(15);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryDeviceList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryDeviceList();
  }

  const queryDeviceList = () => {
    const queryParam = { isRemoved: false };
    if (anziotUtils.isNotNull(searchForm.deviceMac)) {
      queryParam.deviceMac = searchForm.deviceMac;
    }
    if (anziotUtils.isNotNull(searchForm.deviceImei)) {
      queryParam.deviceImei = searchForm.deviceImei;
    }
    if (anziotUtils.isNotNull(searchForm.simIccid)) {
      queryParam.simIccid = searchForm.simIccid;
    }
    if (anziotUtils.isNotNull(searchForm.onlineState)) {
      queryParam.onlineState = searchForm.onlineState;
    }
    if (anziotUtils.isNotNull(searchForm.projectName)) {
      queryParam.projectName = searchForm.projectName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceName)) {
      queryParam.deviceName = searchForm.deviceName;
    }
    if (anziotUtils.isNotNull(searchForm.deviceType)) {
      queryParam.deviceType = searchForm.deviceType;
    }
    request.queryDevices(currentPage.value, pageSize.value, queryParam).then(res => {
      if (res.success) {
        tableData.value = [];
        res.data.rows.forEach((item) => {
          const dataItem = {
            deviceKey: item.deviceKey,
            masterDeviceName: item.masterDeviceName,
            projectName: item.projectName,
            agentName: item.agentName,
            deviceName: item.deviceName,
            deviceMac: item.deviceMac,
            imei: item.deviceImei,
            simIccid: item.simIccid,
            deviceType: item.deviceType,
            deviceTypeName: item.deviceTypeName,
            onlineStatus: item.onlineStatus,
            networkQuality: item.networkQuality,
            callNumberCount: item.callNumberCount,
            onlineTime: anziotUtils.getTimestampStr(item.onlineTime),
            createTime: anziotUtils.getTimestampStr(item.createTime)
          }
          tableData.value.push(dataItem);
        });
        total.value = res.data.total;
        innerRefeshToggleRow()
      } else {
        ElMessage.error('获取项目数据失败');
      }
    }).catch(error => {
      console.log(error)
    });
  };

  const searchFormRef = ref(null);
  const searchForm = reactive({
    deviceName: '',
    projectName: '',
    deviceType: '',
    deviceImei: '',
    simIccid: '',
    onlineState: ''
  });
  const onReset = () => {
    searchFormRef.value.resetFields();
    queryDeviceList();
  }
  const onSearch = () => {
    queryDeviceList();
  };

  // const onDeviceIMEIInput = () => {
  //   if (searchForm.deviceImei.length === 0) {
  //     return
  //   }
  //   const charCount = searchForm.deviceImei.split(';').length - 1
  //   if (charCount !== 6) {
  //     return
  //   }
  //   queryDeviceList();
  // }

  onActivated(
    () => {
      queryDeviceList();
    }
  )

  /* 对话框 */
  const deviceDetailProps = ref({ visible: false, deviceKey: undefined, reload: () => {} })

  const handleDeviceDetail = (deviceKey) => {
    deviceDetailProps.value.visible = true
    deviceDetailProps.value.deviceKey = deviceKey
    deviceDetailProps.value.reload = queryDeviceList
    // console.log('handleDeviceDetail', deviceKey, deviceDetailProps.value)
  }
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }

  .selection {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    /* margin-left: 20px;
    padding: 10px; */
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }
</style>
