<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备更换"
        width="500px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div>
          <div class="centered">
            <el-descriptions
              title="目标设备信息"
              direction="horizontal"
              :column="1"
              border
              >
              <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label" width="240">
                {{ target.deviceMac }}
              </el-descriptions-item>
              <el-descriptions-item label="设备类型" label-class-name="descriptions-item-label">
                {{ target.deviceTypeName }}
              </el-descriptions-item>
              <el-descriptions-item label="从属代理商" label-class-name="descriptions-item-label">
                {{ target.agentName }}
              </el-descriptions-item>
              <el-descriptions-item label="主设备编号" label-class-name="descriptions-item-label">
                {{ target.masterDeviceName }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div style="margin-top: 50px; margin-bottom: 5px" class="centered">
            <el-input v-model="sourceDeviceMac" placeholder="请输入替换设备编号" @input="onDeviceMacInput">
              <template #prepend>替换设备编号</template>
            </el-input>
          </div>
          <div class="centered">
            <el-descriptions
              v-loading="sourceLoading"
              direction="horizontal"
              :column="1"
              border
              >
              <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label"  width="240">
                {{ source.deviceMac }}
              </el-descriptions-item>
              <el-descriptions-item label="设备类型" label-class-name="descriptions-item-label">
                {{ source.deviceTypeName }}
              </el-descriptions-item>
              <el-descriptions-item label="从属代理商" label-class-name="descriptions-item-label">
                {{ source.agentName }}
              </el-descriptions-item>
              <el-descriptions-item label="主设备编号" label-class-name="descriptions-item-label">
                {{ source.masterDeviceName }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div style="margin-bottom: 20px; margin-top: 20px" class="centered">
            <el-button type="primary" @click="doDeviceChange">确认更换</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { ref, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';

    export default {
        name: 'DialogDeviceChange',
        props: {
            device: {
                type: Object,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              sourceDeviceMac.value = ''
              source.value = {}
              // console.log('DialogDeviceChange-openDialog', props);
            };

            const target = computed({
                get() {
                    return props.device
                },
                set(val) {
                    return val
                }
            });

            const source = ref({})

            const sourceLoading = ref(false)
            const sourceDeviceMac = ref('')

            const onDeviceMacInput = () => {
              if (sourceDeviceMac.value === undefined ||
              sourceDeviceMac.value === target.value.deviceMac ||
                (sourceDeviceMac.value.length < 12 &&
                sourceDeviceMac.value.length > 0)) {
                  return
              }
              sourceLoading.value = true
              setTimeout(() => {
                const queryParam = {
                  deviceMac: sourceDeviceMac.value,
                  deviceType: props.device.deviceType
                };
                const deviceInfo = {
                  deviceMac: '暂未查询到设备',
                  deviceTypeName: '暂未查询到设备',
                  agentName: '暂未查询到设备',
                  masterDeviceName: '暂未查询到设备'
                }
                request.queryDevices(1, 1, queryParam).then(res => {
                  if (res.success) {
                    const dev = res.data.rows[0];
                    deviceInfo.deviceKey = dev.deviceKey
                    deviceInfo.deviceMac = dev.deviceMac
                    deviceInfo.deviceTypeName = dev.deviceTypeName
                    deviceInfo.agentName = dev.agentName
                    deviceInfo.masterDeviceName = dev.masterDeviceName
                  }
                  source.value = deviceInfo
                }).catch(error => {
                    console.log(error)
                    source.value = deviceInfo
                });
                sourceLoading.value = false
              }, 300);
              // console.log('onDeviceMacInput', sourceDeviceMac.value, source)
            }

            const doDeviceChange = () => {
              if (!source.value || !source.value.deviceKey) {
                ElMessage.error('替换设备信息错误！');
                return
              }
              const changeParam = {
                deviceKey: source.value.deviceKey
              };
              request.replaceDevice(props.device.deviceKey, changeParam).then(res => {
                if (res.success) {
                  ElMessage.success('设备更换成功');
                  ctx.emit('update:visible', false);
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogDeviceChange-doDeviceChange', props, changeParam);
            }

            return {
              onDeviceMacInput,
              sourceDeviceMac,
              sourceLoading,
              source,
              target,
              isDialogVisible,
              closeDialog,
              openDialog,
              doDeviceChange
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
