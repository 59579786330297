<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>控制台</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="statistic-block">
      <el-row :gutter="16">
      <el-col :span="6">
        <div class="statistic-card">
          <el-statistic :value="trendData.newDeviceCount">
            <template #title>
              <div style="display: inline-flex; align-items: center; font-size: 18px; margin-bottom: 5px;">
                今日激活设备数
                <el-tooltip
                  effect="dark"
                  content="当天内激活设备数量"
                  placement="top"
                >
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer">
            <div class="footer-item">
              <span>比昨日</span>
              <span :style="{ color: (trendData.newDeviceCountChangeRate < 0 ? '#67c23a' : '#f56c6c') }">
                {{ trendData.newDeviceCountChangeRate }}%
                <el-icon v-show="trendData.newDeviceCountChangeRate >= 0">
                  <CaretTop />
                </el-icon>
                <el-icon v-show="trendData.newDeviceCountChangeRate < 0">
                  <CaretBottom />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="statistic-card">
          <el-statistic :value="trendData.callCount">
            <template #title>
              <div style="display: inline-flex; align-items: center; font-size: 18px; margin-bottom: 5px;">
                今日通话数
                <el-tooltip
                  effect="dark"
                  content="当天产生有效通话数量"
                  placement="top"
                >
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer">
            <div class="footer-item">
              <span>比昨日</span>
              <span :style="{ color: (trendData.callCountChangeRate < 0 ? '#67c23a' : '#f56c6c') }">
                {{ trendData.callCountChangeRate }}%
                <el-icon v-show="trendData.callCountChangeRate >= 0">
                  <CaretTop />
                </el-icon>
                <el-icon v-show="trendData.callCountChangeRate < 0">
                  <CaretBottom />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="statistic-card">
          <el-statistic :value="trendData.deviceCount">
            <template #title>
              <div style="display: inline-flex; align-items: center; font-size: 18px; margin-bottom: 5px;">
                在网设备总数
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer">
            <div class="footer-item">
              <span>比上月</span>
              <span :style="{ color: (trendData.deviceCountChangeRate < 0 ? '#67c23a' : '#f56c6c') }">
                {{ trendData.deviceCountChangeRate }}%
                <el-icon v-show="trendData.deviceCountChangeRate >= 0">
                  <CaretTop />
                </el-icon>
                <el-icon v-show="trendData.deviceCountChangeRate < 0">
                  <CaretBottom />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="statistic-card">
          <el-statistic :value="trendData.callNumberCount">
            <template #title>
              <div style="display: inline-flex; align-items: center; font-size: 18px; margin-bottom: 5px;">
                在网新设备数
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer">
            <div class="footer-item">
              <span>比上月</span>
              <span :style="{ color: (trendData.callNumberCountChangeRate < 0 ? '#67c23a' : '#f56c6c') }">
                {{ trendData.callNumberCountChangeRate }}%
                <el-icon v-show="trendData.callNumberCountChangeRate >= 0">
                  <CaretTop />
                </el-icon>
                <el-icon v-show="trendData.callNumberCountChangeRate < 0">
                  <CaretBottom />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    </div>
    <div class="charts-block">
      <el-row>
        <el-col :span="10">
          <div ref="online" style="width: 100%; height: 400px"></div>
        </el-col>
        <el-col :span="14">
          <div ref="activation" style="width: 100%; height: 400px"></div>
        </el-col>
      </el-row>
    </div>
    <div class="table-block">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>48小时内系统事件</span>
          </div>
        </template>
        <el-table
          :data="eventData"
          stripe
          highlight-current-row
          :v-loading = "eventData.length > 0 ? false : true"
          :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%; margin-top: 10px;"
          >
          <el-table-column prop="createTime" label="发生时间" width="230"/>
          <el-table-column prop="event" label="事件" />
        </el-table>
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :small=false
          :disabled=false
          :background=true
          layout="->, total, prev, pager, next"
          :total="total"
          style="margin-top: 10px;"
          @current-change="handleCurrentChange"
        />
      </el-card>
    </div>
  </div>
</template>

<script setup>
  import { ref, onActivated } from 'vue';
  import * as echarts from 'echarts';
  import { ElMessage } from 'element-plus';
  import {
    CaretBottom,
    CaretTop,
    Warning
  } from '@element-plus/icons-vue';
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';

  const online = ref()
  const activation = ref()

  const trendData = ref(
      {
        newDeviceCount: 0,
        newDeviceCountChangeRate: 0,
        callCount: 0,
        callCountChangeRate: 0,
        deviceCount: 0,
        deviceCountChangeRate: 0,
        callNumberCount: 0,
        callNumberCountChangeRate: 0
      }
    );

  onActivated(
    () => {
      init()
    }
  )
  function init() {
    /* 趋势数据初始化 */
    request.queryDashboardTrends().then(res => {
       if (res.success) {
        trendData.value = res.data;
       } else {
        ElMessage.error('获取统计数据信息失败');
       }
    }).catch(error => {
        console.log(error)
    });

    /* 激活设备折线表初始化 */
    const activationChart = echarts.init(activation.value);
    const activationChartOption = {
      title: {
        text: '近7个月激活设备数统计'
      },
      tooltip: {},
      legend: {
        data: ['EC600M', 'ML307A']
      },
      xAxis: {
        data: []
      },
      yAxis: {},
      series: [
        {
          name: 'EC600M',
          type: 'bar',
          stack: 'Device',
          data: []
        },
        {
          name: 'ML307A',
          type: 'bar',
          stack: 'Device',
          data: []
        }
      ]
    };
    // Load Data
    request.queryDashboardDeviceActivations().then(res => {
       if (res.success) {
          const titles = activationChartOption.xAxis.data;
          const datasEc600m = activationChartOption.series[0].data;
          const datasMl307a = activationChartOption.series[1].data;
          res.data.rows.forEach((item) => {
            titles.push(item.date);
            datasEc600m.push(item.ecCount);
            datasMl307a.push(item.mlCount);
          });
          activationChart.setOption(activationChartOption);
       } else {
        ElMessage.error('获取统计数据信息失败');
       }
    }).catch(error => {
        console.log(error)
    });

    /* 设备在线饼图初始化 */
    const onlineChart = echarts.init(online.value);
    const onlineChartOption = {
      title: {
        text: '当前在线设备数统计'
      },
      tooltip: {},
      legend: {
        top: '30px'
      },
      series: [
        {
          name: '设备数量',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '70%'],
          startAngle: 180,
          label: {
            show: true,
            formatter(param) {
              return param.name + ' (' + param.percent * 2 + '%)';
            }
          },
          data: [
            { value: 0, name: '在线' },
            { value: 1, name: '离线' },
            {
              // make an record to fill the bottom 50%
              value: 1,
              itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          ]
        }
      ]
    };
    // Load Data
    request.queryDashboardOnlineDevices().then(res => {
       if (res.success) {
          const onlineCount = res.data.onlineDeviceCount;
          const offlineCount = res.data.offlineDeviceCount;
          const totalCount = onlineCount + offlineCount;
          onlineChartOption.series[0].data[0].value = onlineCount;
          onlineChartOption.series[0].data[1].value = offlineCount;
          onlineChartOption.series[0].data[2].value = totalCount;
          onlineChart.setOption(onlineChartOption);
       } else {
        ElMessage.error('获取统计数据信息失败');
       }
    }).catch(error => {
        console.log(error)
    });
  }

  /* 事件列表 */
  const eventData = ref([]);

  const currentPage = ref(1);
  const pageSize = ref(8);
  const total = ref(0);

  const handleCurrentChange = (val) => {
    currentPage.value = val;
    request.queryDashboardEvents(currentPage.value, pageSize.value).then(res => {
      eventData.value = [];
       if (res.success) {
          res.data.rows.forEach((item) => {
            eventData.value.push({ event: item.event, createTime: anziotUtils.getTimestampStr(item.time) });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取系统事件数据失败');
       }
    }).catch(error => {
        console.log(error)
    });
  }

  handleCurrentChange(1)
</script>

<style>
:global(h2#card-usage ~ .example .example-showcase) {
  background-color: var(--el-fill-color) !important;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}

.statistic-block {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  margin-bottom: 70px;
  font-size: larger;
}

.charts-block {
  margin-left: 20px;
}
</style>
