<template>
    <div class="common-layout">
    <el-container>
      <el-header>
        <div style="height: 60px; line-height: 60px; text-align: center">
          <el-menu
            class="el-menu-header"
            background-color="#252a2f"
            text-color="#F2F2F299"
            active-text-color="#F2F2F299"
            mode="horizontal"
            :ellipsis="false"
            @select="handleSelect"
          >
            <b style="color: rgb(255, 255, 255); margin-left: 20px; font-size:18px" >云对讲管理系统</b>
            <div class="flex-grow"></div>
            <el-sub-menu index="1">
              <template #title>系统管理员</template>
              <el-menu-item index="11" >修改密码</el-menu-item>
              <el-menu-item index="12" divided>退出</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
      </el-header>
      <el-container class="container-workspace">
        <el-aside :width="'230px'">
          <el-menu
            :key="new Date().getTime()"
            :default-openeds="['1']"
            active-text-color="#ffd04b"
            background-color="#545c64"
            class="el-menu-aside"
            default-active="1"
            text-color="#eee"
            @select="handleMenuSelect"
          >
            <el-menu-item index="1">
              <el-icon><DataLine /></el-icon>
              <span>控制台</span>
            </el-menu-item>
            <el-sub-menu index="7" v-if="currentAccount === 'adsysmin'">
              <template #title>
                <el-icon><Monitor /></el-icon>
                <span style="font-size: 16px;">生产工作台</span>
              </template>
              <el-menu-item index="71">
                <template #title>
                  <el-icon><Brush /></el-icon>
                  <span>设备激活</span>
                </template>
              </el-menu-item>
              <el-menu-item index="72">
                <template #title>
                  <el-icon><SetUp /></el-icon>
                  <span>代理商主/分机添加</span>
                </template>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="2">
              <template #title>
                <el-icon><Monitor /></el-icon>
                <span style="font-size: 16px;">设备管理</span>
              </template>
              <el-menu-item index="21">
                <template #title>
                  <el-icon><Cellphone /></el-icon>
                  <span>设备列表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="20">
                <template #title>
                  <el-icon><Guide /></el-icon>
                  <span>设备树</span>
                </template>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item index="3">
              <el-icon><Phone /></el-icon>
              <span>通话记录</span>
            </el-menu-item>
            <el-menu-item index="5">
              <el-icon><User /></el-icon>
              <span>账号管理</span>
            </el-menu-item>
            <el-menu-item index="8">
              <el-icon><WindPower /></el-icon>
              <span>手机APP绑定</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <section>
            <router-view v-slot="{ Component }">
              <transition name="fade-transform" mode="out-in">
                <keep-alive>
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </section>
        </el-main>
      </el-container>
    </el-container>
    <DialogSystemResetPwd v-model:visible="resetPwdVisible"/>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import router from '../router';
  // , WindPower, Delete, Compass, Money, CopyDocument
  import { Monitor, User, Brush, Cellphone, Phone, DataLine, Guide, SetUp, WindPower } from '@element-plus/icons-vue';
  import { ElMessage } from 'element-plus';
  import request from '../utils/interface.js';
  import DialogSystemResetPwd from '@/components/dialog/DialogSystemResetPwd.vue';

  const currentAccount = ref(localStorage.getItem('account'))
  const resetPwdVisible = ref(false)

  const handleSelect = (key, keyPath) => {
    // console.log(key, keyPath)
    if (key === '11') {
      resetPwdVisible.value = true;
    } else if (key === '12') {
      request.logout().then(res => {
        if (res.success) {
          ElMessage.success('登出成功');
          parent.location.reload();
        } else {
          ElMessage.error(res.message);
        }
      }).catch(error => {
          console.log(error)
      });
    }
  }

  const handleMenuSelect = (key, keyPath) => {
    let target = 'list'
    if (key === '2') {
      target = 'device'
    } else if (key === '1') {
      target = 'dashboard'
    } else if (key === '3') {
      target = 'callRecord'
    } else if (key === '4') {
      target = 'project'
    } else if (key === '5') {
      target = 'account'
    } else if (key === '6') {
      target = 'agent'
    } else if (key === '22') {
      target = 'newcomer'
    } else if (key === '21') {
      target = 'list'
    } else if (key === '23') {
      target = 'match'
    } else if (key === '24') {
      target = 'change'
    } else if (key === '25') {
      target = 'remove'
    } else if (key === '20') {
      target = 'dtree'
    } else if (key === '26') {
      target = 'dbatch'
    } else if (key === '71') {
      target = 'newcomer'
    } else if (key === '72') {
      target = 'dbatch'
    } else if (key === '8') {
      ElMessage.success('功能内测中，如需体验，请联系我司对接负责人申请开通......');
      return
    }
    router.push({
      path: target
    });
  }
</script>

<style>
html,body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.common-layout {
  height: 100vh;
}

.el-container {
  height: 100%;
}

.el-header {
  background-color: #252a2f;
}

.el-aside {
  height: 100%;
  background-color: #545c64;
}

.container-workspace {
  height: 100%;
}

.el-main {
  height: 100%;
}

.el-menu-header {
  background-color: #252a2f;
}

.el-menu-aside {
  /* margin-top: 55px; */
}

.el-menu-item {
  font-size:16px !important;
}

.flex-grow {
  flex-grow: 1;
}

.el-breadcrumb__item {
  font-size: 16px;
}

.icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
