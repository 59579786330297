<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备分配代理商"
        width="500px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-descriptions
              :model="deviceDispatchForm"
              direction="horizontal"
              :column="1"
              border
          >
            <el-descriptions-item label="设备名称" label-class-name="descriptions-item-label">
              {{ deviceDispatchForm.deviceName }}
            </el-descriptions-item>
            <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
              {{ deviceDispatchForm.deviceMac }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 20px;">
            <el-form label-width="100px" :model="deviceDispatchForm" ref="deviceDispatchFormRef">
              <el-form-item prop="agentKey" label="代理商">
                <el-select
                  v-model="deviceDispatchForm.agentName"
                  placeholder="请选择代理商"
                  clearable
                  filterable
                  remote
                  :remote-method="loadAgentList"
                  :loading="loadingAgentListQuery"
                  @change="refreshAgentData"
                  @focus="loadAgentList"
                >
                  <el-option
                    v-for="item in agentList"
                    :key="item.agentKey"
                    :label="item.agentName"
                    :value="item.agentKey"
                  />
                </el-select>
              </el-form-item>
              <el-form-item style="margin-top: 20px;">
                <el-button type="primary" @click="doDeviceDispatch">分配</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed, ref } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';

    export default {
        name: 'DialogDeviceDispatchAgent',
        props: {
          deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceDispatchFormRef = ref(null);
            const deviceDispatchForm = reactive({
                deviceKey: '',
                deviceName: '',
                deviceMac: '',
                agentKey: '',
                agentName: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              // console.log('DialogDeviceDispatch-openDialog', props.deviceKey, deviceDispatchForm);
              deviceDispatchFormRef.value.resetFields();
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        deviceDispatchForm.deviceKey = device.deviceKey;
                        deviceDispatchForm.deviceName = device.deviceName;
                        deviceDispatchForm.deviceMac = device.deviceMac;
                        deviceDispatchForm.agentKey = device.agentKey;
                        deviceDispatchForm.agentName = device.agentName;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
            };

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const refreshAgentData = (val) => {
              deviceDispatchForm.agentKey = val;
            }

            const loadAgentList = (param) => {
              const queryParam = { agentName: '' };
              if (typeof(param) === 'string') {
                queryParam.agentName = param;
              }
              loadingAgentListQuery.value = true
                setTimeout(() => {
                  request.queryAgents(1, 10000, queryParam).then(res => {
                    if (res.success) {
                        agentList.value = [];
                        res.data.rows.forEach((item) => {
                          agentList.value.push({
                            agentKey: item.agentKey,
                            agentName: item.agentName
                          });
                        });
                    } else {
                      ElMessage.error('获取代理商数据失败');
                    }
                  }).catch(error => {
                      console.log(error)
                  });
                  loadingAgentListQuery.value = false
                }, 300)
            }

            const doDeviceDispatch = () => {
              request.updateDeviceAgent(props.deviceKey, deviceDispatchForm.agentKey).then(res => {
                    if (res.success) {
                        ElMessage.success(res.message);
                        ctx.emit('update:visible', false);
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
              // console.log('DialogDeviceDispatch-doDeviceDispatch', props.deviceKey, deviceDispatchForm);
            }

            return {
              agentList,
              loadingAgentListQuery,
              deviceDispatchFormRef,
              deviceDispatchForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              refreshAgentData,
              loadAgentList,
              doDeviceDispatch
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
