<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row style="margin-top: 15px">
      <el-col :span="6">
        <div class=tree>
          <el-input
            clearable
            placeholder="请输入搜索的代理商名称"
            @input="onAgentQueryChanged"
          />
          <el-tree-v2
            ref="treeRef"
            :data="treeData"
            :props="treeProps"
            highlight-current
            :default-expanded-keys="defaultExpanedKey"
            node-key="key"
            :height="960"
            :filter-method="agentFilter"
            @node-click="onTreeNodeClick"
          />
          <!-- <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span v-if="searchForm.agentKey === node.key">
                <el-button type="success" size="small" @click="remove(node, data)">创建下级代理商</el-button>
                <el-button type="danger" size="small" @click="remove(node, data)">删除</el-button>
              </span>
            </span>
          </template> -->
        </div>
      </el-col>
      <el-col :span="18">
        <el-row>
          <el-col :span="20">
            <el-descriptions
                v-loading="agentLoading"
                :model="agentData"
                direction="horizontal"
                :column="3"
                border
            >
              <el-descriptions-item label="当前代理商名称" label-class-name="descriptions-item-label" :span="2">
                {{ agentData.agentName }}
              </el-descriptions-item>
              <el-descriptions-item label="创建时间" label-class-name="descriptions-item-label">
                {{ agentData.createTime }}
              </el-descriptions-item>
              <el-descriptions-item label="主机数量" label-class-name="descriptions-item-label">
                {{ agentData.deviceHostCount }}
              </el-descriptions-item>
              <el-descriptions-item label="分机数量" label-class-name="descriptions-item-label">
                {{ agentData.deviceExtCount }}
              </el-descriptions-item>
              <el-descriptions-item label="总局数" label-class-name="descriptions-item-label">
                {{ agentData.callNumberCount }}
              </el-descriptions-item>
              <el-descriptions-item label="从属关系" label-class-name="descriptions-item-label" :span="3">
                {{ agentData.agentTree }}
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :span="4">
            <div>
              <el-button type="success" :disabled="!agentData.agentName" @click="handleAgentCreate" style="margin-left: 20px">创建下级代理商</el-button>
              <el-button type="danger" :disabled="isAgentDeleteDisable" @click="handleAgentDelete" style="margin-left: 20px; margin-top: 30px;">删除当前代理商</el-button>
            </div>
          </el-col>
        </el-row>
        <el-divider />
        <div class="search">
          <el-form :inline="true" class="search_form" :model="searchForm" ref="searchFormRef">
            <el-form-item label="账号名称" prop="nickname">
              <el-input v-model="searchForm.nickname" placeholder="请输入账号名称"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account">
              <el-input v-model="searchForm.account" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item class="search_btns">
              <el-button type="primary" @click="onSearch">查询</el-button>
              <el-button type="info" @click="onReset">重置</el-button>
              <el-button @click="handleAccountCreate">新建登录账号</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          v-loading="accountLoading"
          :data="tableData"
          stripe
          highlight-current-row
          :v-loading = "tableData.length > 0 ? false : true"
          :header-cell-style="{background:'#DDDDDD', color:'gray', 'text-align': 'center'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%; margin-top: 10px;"
          >
          <el-table-column prop="nickname" label="账号名称" />
          <el-table-column prop="account" label="账号" />
          <el-table-column prop="accountTypeName" label="账号类型"  width="120"/>
          <el-table-column prop="stateName" label="账号状态"  width="100">
            <template #default="scope">
              <el-tag class="ml-2" :type="scope.row.state === 0 ? 'success' : 'error'">{{ scope.row.stateName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" />
          <el-table-column fixed="right" label="操作" width="180">
            <template #default="scope">
              <el-button link type="primary" size="small" @click="handleAccountEdit(scope.row)">设置</el-button>
              <el-popconfirm
                width="350"
                :title="'确定要重置账号‘' + scope.row.account + '’的密码?'"
                @confirm="handleAccountReset(scope.row.accountKey)"
                confirm-button-text="重置"
                cancel-button-text="取消"
              >
                <template #reference>
                  <el-button link type="primary" size="small">重置密码</el-button>
                </template>
              </el-popconfirm>
              <el-popconfirm
                width="350"
                :title="'确定要删除账号‘' + scope.row.account + '’?'"
                @confirm="handleAccountDelete(scope.row.accountKey)"
                confirm-button-text="确认删除"
                confirm-button-type="danger"
                :icon="InfoFilled"
                icon-color="#EF0000"
                cancel-button-text="取消"
              >
                <template #reference>
                  <el-button link type="danger" size="small">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          :small=false
          :disabled=false
          :background=true
          layout="->, total, prev, pager, next, sizes"
          :total="total"
          style="margin-top: 10px;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <DialogAccountEdit
      v-model:visible="accountEditProps.visible"
      v-model:accountDetail="accountEditProps.accountDetail"
      v-model:reload="pageReload"
    />
    <DialogAccountCreate
      v-model:visible="accountCreateProps.visible"
      v-model:agentKey="accountCreateProps.agentKey"
      v-model:agentName="accountCreateProps.agentName"
      v-model:reload="pageReload"
    />
    <DialogAgentCreate
      v-model:visible="agentCreateProps.visible"
      v-model:agentKey="agentCreateProps.agentKey"
      v-model:agentName="agentCreateProps.agentName"
      v-model:reload="pageAllReload"
    />
    <DialogAgentDelete
      v-model:visible="agentDeleteProps.visible"
      v-model:agentKey="agentDeleteProps.agentKey"
      v-model:agentName="agentDeleteProps.agentName"
      v-model:targetAgentName="agentDeleteProps.targetAgentName"
      v-model:targetAgentKey="agentDeleteProps.targetAgentKey"
      v-model:reload="pageAllReload"
    />
  </div>
</template>

<script setup>
  import { reactive, ref, onActivated } from 'vue';
  import { ElMessage, ElTag } from 'element-plus';
  import { InfoFilled } from '@element-plus/icons-vue'
  import request from '../utils/interface.js';
  import anziotUtils from '../utils/anziotUtils.js';
  import DialogAccountEdit from '@/components/dialog/DialogAccountEdit.vue';
  import DialogAccountCreate from '@/components/dialog/DialogAccountCreate.vue';
  import DialogAgentCreate from '@/components/dialog/DialogAgentCreate.vue';
  import DialogAgentDelete from '@/components/dialog/DialogAgentDelete.vue';

  const defaultAgentKey = 'e0b354f2ba39e215e25262081d5d0d33'
  const agentLoading = ref(false)
  const accountLoading = ref(false)

  // Tree
  const defaultExpanedKey = ref([defaultAgentKey]);
  const treeProps = {
    value: 'key',
    label: 'name',
    type: 'type',
    children: 'children'
  }

  const queryTreeData = () => {
    request.queryAgentTree().then(res => {
      if (res.success) {
        treeData.value = res.data;
      } else {
        ElMessage.error('获取账号数据失败');
      }
    }).catch(error => {
        console.log(error)
    });
  }
  const treeRef = ref()
  const treeData = ref(queryTreeData());

  const onTreeNodeClick = (data, node, event) => {
    searchForm.agentKey = data.key
    queryAccountList();
    queryAgentDetail();
  }

  const onAgentQueryChanged = (query) => {
    treeRef.value.filter(query)
  }

  const agentFilter = (query, node) => {
    return node.name.search(query) !== -1;
  };

  // Agent
  const isAgentDeleteDisable = ref(false)
  const agentData = ref({
    agentName: '',
    projectCount: 0,
    deviceHostCount: 0,
    deviceExtCount: 0,
    callNumberCount: 0,
    createTime: '',
    agentTree: ''
  });

  const queryAgentDetail = () => {
    agentLoading.value = true
    request.queryAgentDetail(searchForm.agentKey).then(res => {
       if (res.success) {
        const obj = res.data;
        let agentTree = '';
        obj.agentPath.forEach((item) => {
          agentTree = agentTree + ' / ' + item.name;
        });
        agentData.value = {
          agentName: obj.agentName,
          projectCount: obj.projectCount,
          deviceHostCount: obj.hostDeviceCount,
          deviceExtCount: obj.extentionCount,
          callNumberCount: obj.callNumberCount,
          createTime: anziotUtils.getTimestampStr(obj.createTime),
          agentTree: agentTree
        }
        if (obj.agentPath.length > 1) {
          isAgentDeleteDisable.value = false
          const parentNode = obj.agentPath[obj.agentPath.length - 2]
          // console.log(parentNode)
          agentData.value.belongAgentName = parentNode.name
          agentData.value.belongAgentKey = parentNode.key
        } else {
          isAgentDeleteDisable.value = true
        }
       } else {
        ElMessage.error('获取代理商数据失败');
       }
       agentLoading.value = false
    }).catch(error => {
        console.log(error)
    });
  }

  // Account
  const tableData = ref([]);

  const currentPage = ref(1);
  const pageSize = ref(20);
  const total = ref(0);

  const handleSizeChange = (val) => {
    pageSize.value = val;
    queryAccountList();
  }
  const handleCurrentChange = (val) => {
    currentPage.value = val;
    queryAccountList();
  }

  const queryAccountList = () => {
    accountLoading.value = true
    const queryParam = {};
    if (anziotUtils.isNotNull(searchForm.nickname)) {
      queryParam.nickname = searchForm.nickname;
    }
    if (anziotUtils.isNotNull(searchForm.account)) {
      queryParam.account = searchForm.account;
    }
    if (anziotUtils.isNotNull(searchForm.agentName)) {
      queryParam.agentName = searchForm.agentName;
    }
    if (anziotUtils.isNotNull(searchForm.agentKey)) {
      queryParam.agentKey = searchForm.agentKey;
    } else {
      queryParam.agentKey = defaultAgentKey;
    }
    request.queryAccounts(currentPage.value, pageSize.value, queryParam).then(res => {
       if (res.success) {
          tableData.value = [];
          res.data.rows.forEach((item) => {
            tableData.value.push({
              accountKey: item.accountKey,
              account: item.account,
              accountTypeName: item.accountTypeName,
              nickname: item.nickname,
              agentName: item.agentName,
              state: item.state,
              stateName: item.stateName,
              createTime: anziotUtils.getTimestampStr(item.createTime)
            });
          });
          total.value = res.data.total;
       } else {
        ElMessage.error('获取系统事件数据失败');
       }
       accountLoading.value = false
    }).catch(error => {
        console.log(error)
    });
  }

  const searchFormRef = ref(null);
  const searchForm = reactive({
    accountName: '',
    agentName: '',
    agentKey: defaultAgentKey
  });
  const onReset = () => {
    searchFormRef.value.resetFields();
    queryAccountList();
  };

  const onSearch = () => {
    queryAccountList();
  };

  onActivated(
    () => {
      queryAccountList();
      queryAgentDetail();
    }
  )

  const pageAllReload = (agentKey) => {
    // console.log('Account-pageAllReload', agentKey)
    if (!agentKey) {
      searchForm.agentKey = agentKey
      treeRef.value.setCurrentKey(agentKey, true);
    }
    queryTreeData();
    queryAccountList();
    queryAgentDetail();
  }

  const pageReload = () => {
    // console.log('Account-pageReload')
    queryAccountList();
    queryAgentDetail();
  }

  /* 对话框 */
  const accountEditProps = ref({ visible: false, accountDetail: undefined, reload: () => {} });
  const accountCreateProps = ref({ visible: false, reload: () => {} });
  const agentCreateProps = ref({ visible: false, reload: () => {} });
  const agentDeleteProps = ref({ visible: false, reload: () => {} });

  const handleAccountReset = (accountKey) => {
    request.resetAccount(accountKey).then(res => {
      if (res.success) {
        ElMessage.success('重置成功');
        queryAccountList();
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
    // console.log('handleAccountReset', accountKey);
  }

  const handleAccountDelete = (accountKey) => {
    request.deleteAccount(accountKey).then(res => {
      if (res.success) {
        ElMessage.success('删除完成');
        queryAccountList();
      } else {
        ElMessage.error(res.message);
      }
    }).catch(error => {
        console.log(error)
    });
    pageReload();
    // console.log('handleAccountDelete', accountKey);
  }

  const handleAccountEdit = (item) => {
    const props = accountEditProps.value;
    props.visible = true;
    props.accountDetail = item;
    props.reload = pageReload;
    // console.log('handleAccountEdit', accountEditProps.value)
  }

  const handleAccountCreate = () => {
    const props = accountCreateProps.value
    props.visible = true;
    props.agentKey = searchForm.agentKey;
    props.agentName = agentData.value.agentName;
    props.reload = pageReload;
    // console.log('handleAccountCreate', accountCreateProps.value)
  };

  const handleAgentDelete = () => {
    const props = agentDeleteProps.value
    props.visible = true;
    props.agentKey = searchForm.agentKey;
    props.agentName = agentData.value.agentName;
    props.targetAgentName = agentData.value.belongAgentName;
    props.targetAgentKey = agentData.value.belongAgentKey;
    props.reload = pageAllReload;
    // console.log('handleAgentDelete', agentDeleteProps.value)
  };

  const handleAgentCreate =() => {
    const props = agentCreateProps.value
    props.visible = true;
    props.agentKey = searchForm.agentKey;
    props.agentName = agentData.value.agentName;
    props.reload = pageAllReload;
    // console.log('handleAgentCreate', agentCreateProps.value)
  };
</script>

<style>
  .search {
    width: 100%;
    margin-top: 30px;
    border: 1px solid rgba(5, 5, 5, 0.10);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }

  .search_form {
    margin-top: 20px;
    margin-left: 20px;
  }

  .search_btns {
    right: 20px;
  }

  .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }
</style>
