<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="账号设置"
        width="550px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <span style="margin-left: 30px; font-size: 26;"> 所属代理商： {{ accountEditForm.agentName }}</span>
          <span style="margin-left: 30px; font-size: 26;"> 账号： {{ accountEditForm.account }}</span>
          <div style="margin-top: 20px;">
            <el-form label-width="100px" :model="accountEditForm" ref="accountEditFormRef">
              <el-form-item prop="nickname" label="账号名称">
                <el-input type="text" v-model="accountEditForm.nickname"></el-input>
              </el-form-item>
              <el-form-item prop="state" label="账号状态">
                <el-switch
                  v-model="accountEditForm.state"
                  inline-prompt
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  active-text="正常"
                  inactive-text="禁用"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="doAccountEdit">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import { reactive, ref, computed } from 'vue';
  import { ElMessage } from 'element-plus';
  import request from '../../utils/interface.js';

    export default {
        name: 'DialogAccountEdit',
        props: {
            accountDetail: {
                type: Object,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const accountEditForm = reactive({
                nickname: '未知',
                agentName: '未知',
                account: '未知',
                state: true
            });

            const agentList = ref([])
            const loadingAgentListQuery = ref(false)

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              accountEditForm.accountKey = props.accountDetail.accountKey;
              accountEditForm.nickname = props.accountDetail.nickname;
              accountEditForm.agentName = props.accountDetail.agentName;
              accountEditForm.account = props.accountDetail.account;
              accountEditForm.state = props.accountDetail.state === 0;
              // console.log('DialogAccountEdit-openDialog', props.accountDetail, accountEditForm);
            };

            const doAccountEdit = () => {
              const updateParam = {
                nickname: accountEditForm.nickname,
                state: accountEditForm.state ? 0 : 1
              };
              request.updateAccount(accountEditForm.accountKey, updateParam).then(res => {
                if (res.success) {
                  ElMessage.success('更新成功');
                  ctx.emit('update:visible', false);
                  props.reload();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              props.reload();
              // console.log('DialogAccountEdit-doAccountEdit', props, accountEditForm);
            }

            const loadAgentList = (param) => {
              if (param) {
                loadingAgentListQuery.value = true
                setTimeout(() => {
                  loadingAgentListQuery.value = false
                }, 200)
              } else {
                agentList.value = []
              }
            }

            return {
              accountEditForm,
              agentList,
              isDialogVisible,
              loadingAgentListQuery,
              closeDialog,
              openDialog,
              doAccountEdit,
              loadAgentList
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
</style>
